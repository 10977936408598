import React, { useState, useEffect } from "react";
import "./CountdownPage.css";
import { imagesStarrySky, imagesStarrySkyWebp } from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";
import LogoAnimation from "../../navbar/logoComponent/LogoAnimation";

const CountdownPage = () => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const targetDate = new Date("2024-08-26T00:00:00").getTime();
    const calculateDaysLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;
      const days = Math.ceil(difference / (1000 * 60 * 60 * 24));
      setDaysLeft(days);
    };
    calculateDaysLeft();
    const interval = setInterval(calculateDaysLeft, 86400000); // Mettre à jour tous les jours
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loadingScreen">
      <LazyImage
        srcPng={imagesStarrySky.loadingScreen}
        srcWebp={imagesStarrySkyWebp.loadingScreenWebp}
        alt="Loading Screen Background"
        className="loadingScreenBackground"
        priorityLevel={1}
      />

      <div className="loadingContent">
        <div className="logoAnimationContainer">
          <LogoAnimation />
        </div>
        <div className="loadingTitle">
          <h1>
            The Scrum Nomad<span className="trademark">&trade;</span>
            <span className="copyright">&copy;</span>
          </h1>
          <h2>by Pierre Filliau</h2>
        </div>
        <div className="launchButton disabled">
          <LazyImage
            srcPng={imagesStarrySky.buttonLoadingScreen}
            srcWebp={imagesStarrySkyWebp.buttonLoadingScreenWebp}
            alt="Indisponible"
            priorityLevel={1}
          />
          <div className="overlay">Locked</div>
        </div>
        <div className="countdown">
          <p>Launching in:</p>
          <h3>{daysLeft} days</h3>
        </div>
      </div>
    </div>
  );
};

export default CountdownPage;
