import React from "react";
import {
  flyingRocks,
  imagesBlueSky,
  imagesBlueSkyWebp,
} from "@data/blueSkyData";
import LazyImage from "@components/LazyImage";

const FlyingRocks = () => (
  <>
    {flyingRocks.map((className, index) => (
      <div key={`${className}-${index}`} className={`${className}Container`}>
        <div className="floatingWrapper">
          <div className={`${className}Wrapper`}>
            <LazyImage
              className={className}
              srcPng={imagesBlueSky[className]}
              srcWebp={imagesBlueSkyWebp[`${className}Webp`]}
              alt=""
              priorityLevel={3}
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

export default FlyingRocks;
