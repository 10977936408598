import React from "react";
import {
  planets,
  imagesStarrySky,
  imagesStarrySkyWebp,
} from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";

const Planets = () => (
  <div className="planetsWrapper">
    {planets.map((className, index) => (
      <div key={`${className}-${index}`} className={`${className}Container`}>
        <div className="floatingWrapper">
          <div className={`${className}Wrapper`}>
            <LazyImage
              className={className}
              srcPng={imagesStarrySky[className]}
              srcWebp={imagesStarrySkyWebp[`${className}Webp`]}
              alt=""
              priorityLevel={2}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default Planets;
