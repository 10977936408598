import React from "react";
import {
  spaceshipGroups,
  imagesStarrySky,
  imagesStarrySkyWebp,
} from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";

const SpaceShips = () => (
  <>
    {spaceshipGroups.map((group) =>
      group.map((className, index) => (
        <div key={`${className}-${index}`} className={`${className}Container`}>
          <div className={`${className}Wrapper`}>
            <LazyImage
              className={className}
              srcPng={imagesStarrySky[className]}
              srcWebp={imagesStarrySkyWebp[`${className}Webp`]}
              alt=""
              priorityLevel={2}
            />
          </div>
        </div>
      ))
    )}
  </>
);

export default SpaceShips;
