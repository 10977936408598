import React, { useCallback, useContext } from "react";
import scrollDownImage from "@assets/navbarAssets/scroll_down_logo.png";
import scrollDownImageWebp from "@assets/navbarAssets/scroll_down_logo.webp";
import "./ScrollToBottomButton.css";
import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";
import LazyImage from "@components/LazyImage";
import { logButtonClick } from "@utils/analytics";

function ScrollToBottomButton({ onMinimizeBubbles }) {
  const { lang } = useContext(LangContext);

  const scrollToBottom = useCallback(() => {
    logButtonClick("ScrollToBottomButton", "Click", "Scroll to Bottom");

    onMinimizeBubbles();
    let isUserScrolling = false;
    const startTime = performance.now();
    const duration = 100000;

    const startScrollPosition = window.scrollY;
    const totalScrollDistance =
      document.documentElement.scrollHeight - window.innerHeight;

    const stopScrolling = () => (isUserScrolling = true);

    window.addEventListener("wheel", stopScrolling);
    window.addEventListener("touchmove", stopScrolling);

    const scroll = (time) => {
      if (isUserScrolling) {
        window.removeEventListener("wheel", stopScrolling);
        window.removeEventListener("touchmove", stopScrolling);
        return;
      }

      const elapsedTime = time - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      window.scrollTo(0, startScrollPosition + totalScrollDistance * progress);

      if (elapsedTime < duration) requestAnimationFrame(scroll);
    };

    requestAnimationFrame(scroll);
  }, [onMinimizeBubbles]);

  return (
    <div className="hover-container" onClick={scrollToBottom}>
      <LazyImage
        srcPng={scrollDownImage}
        srcWebp={scrollDownImageWebp}
        alt="Scroll to bottom"
        className="scrollToBottomButton"
        priorityLevel={1}
      />
      <div className="hover-text">{texts[lang].hovers.scrollDown}</div>
    </div>
  );
}

export default ScrollToBottomButton;
