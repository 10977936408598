import React, { useState, useEffect, useRef, useContext } from "react";
import { gsap } from "gsap";
import "./InfoIcon.css";
//png imports
import discoveryLogo from "@assets/loadingPageAssets/discoveryLogo.png";
import infoLogo from "@assets/loadingPageAssets/infoLogo2.png";
import infoLogo2 from "@assets/loadingPageAssets/infoLogo.png";
import infoPanel1 from "@assets/loadingPageAssets/infoPanel.png";
import infoPanel2 from "@assets/loadingPageAssets/infoPanel2.png";
import musicLogo from "@assets/loadingPageAssets/musicLogo.png";
import redArrow1 from "@assets/loadingPageAssets/redArrow1.png";
import redArrow2 from "@assets/loadingPageAssets/redArrow2.png";
import redArrow3 from "@assets/loadingPageAssets/redArrow3.png";
import redArrow4 from "@assets/loadingPageAssets/redArrow4.png";
import redArrow6 from "@assets/loadingPageAssets/redArrow6.png";
import characterLoading from "@assets/loadingPageAssets/characterLoading.png";
import infoPanelHighlight from "@assets/loadingPageAssets/infoPanelHighlight.png";
import infoPanelHighlight2 from "@assets/loadingPageAssets/infoPanelHighlight2.png";

//webP imports
import discoveryLogoWebp from "@assets/loadingPageAssets/discoveryLogo.webp";
import infoLogoWebp from "@assets/loadingPageAssets/infoLogo2.webp";
import infoLogo2Webp from "@assets/loadingPageAssets/infoLogo.webp";
import infoPanel1Webp from "@assets/loadingPageAssets/infoPanel.webp";
import infoPanel2Webp from "@assets/loadingPageAssets/infoPanel2.webp";
import musicLogoWebp from "@assets/loadingPageAssets/musicLogo.webp";
import redArrow1Webp from "@assets/loadingPageAssets/redArrow1.webp";
import redArrow2Webp from "@assets/loadingPageAssets/redArrow2.webp";
import redArrow3Webp from "@assets/loadingPageAssets/redArrow3.webp";
import redArrow4Webp from "@assets/loadingPageAssets/redArrow4.webp";
import redArrow6Webp from "@assets/loadingPageAssets/redArrow6.webp";
import characterLoadingWebp from "@assets/loadingPageAssets/characterLoading.webp";
import infoPanelHighlightWebp from "@assets/loadingPageAssets/infoPanelHighlight.webp";
import infoPanelHighlight2Webp from "@assets/loadingPageAssets/infoPanelHighlight2.webp";

import FullscreenToggleButton from "@components/navbar/fullScreenButton/FullscreenToggleButton";
import LanguageSwitcher from "@components/navbar/languageSwitcher/LanguageSwitcher";
import { LangContext } from "@context/LangContext";
import { texts } from "@data/textsData";
import LazyImage from "@components/LazyImage";

const InfoIcon = () => {
  const [showPanel1, setShowPanel1] = useState(false);
  const [showPanel2, setShowPanel2] = useState(false);
  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);

  const panelRef1 = useRef(null);
  const panelRef2 = useRef(null);
  const iconRef1 = useRef(null);
  const iconRef2 = useRef(null);

  const { lang } = useContext(LangContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        panelRef1.current &&
        !panelRef1.current.contains(event.target) &&
        !iconRef1.current.contains(event.target)
      ) {
        setShowPanel1(false);
        setShowContent1(false);
      }
      if (
        panelRef2.current &&
        !panelRef2.current.contains(event.target) &&
        !iconRef2.current.contains(event.target)
      ) {
        setShowPanel2(false);
        setShowContent2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showPanel1) {
      gsap.to(".infoPanel1, .buttonPanel1", {
        opacity: 1,
        y: 0,
        duration: 0.3,
        pointerEvents: "auto",
      });
      document.querySelector(".buttonPanel1").classList.add("visible");
    } else {
      gsap.to(".infoPanel1, .buttonPanel1", {
        opacity: 0,
        y: 20,
        duration: 0.3,
        pointerEvents: "none",
      });
      document.querySelector(".buttonPanel1").classList.remove("visible");
    }

    if (showPanel2) {
      gsap.to(".infoPanel2, .buttonPanel2", {
        opacity: 1,
        y: 0,
        duration: 0.3,
        pointerEvents: "auto",
      });
      document.querySelector(".buttonPanel2").classList.add("visible");
    } else {
      gsap.to(".infoPanel2, .buttonPanel2", {
        opacity: 0,
        y: 20,
        duration: 0.3,
        pointerEvents: "none",
      });
      document.querySelector(".buttonPanel2").classList.remove("visible");
    }

    if (showContent1) {
      gsap.to(".hoverContent1 .hoverImg, .hoverContent1 .hoverText", {
        opacity: 1,
        y: 0,
        duration: 0.3,
        stagger: 0.1,
        pointerEvents: "auto",
      });
    } else {
      gsap.to(".hoverContent1 .hoverImg, .hoverContent1 .hoverText", {
        opacity: 0,
        y: 20,
        duration: 0.3,
        stagger: -0.1,
        pointerEvents: "none",
      });
    }

    if (showContent2) {
      gsap.to(".hoverContent2 .hoverImg, .hoverContent2 .hoverText", {
        opacity: 1,
        y: 0,
        duration: 0.3,
        stagger: 0.1,
        pointerEvents: "auto",
      });
    } else {
      gsap.to(".hoverContent2 .hoverImg, .hoverContent2 .hoverText", {
        opacity: 0,
        y: 20,
        duration: 0.3,
        stagger: -0.1,
        pointerEvents: "none",
      });
    }
  }, [showPanel1, showPanel2, showContent1, showContent2]);

  const handleTogglePanel1 = () => {
    setShowPanel1(!showPanel1);
    setShowContent1(false);
  };

  const handleTogglePanel2 = () => {
    setShowPanel2(!showPanel2);
    setShowContent2(false);
  };

  const handleToggleContent1 = () => {
    setShowContent1(!showContent1);
  };

  const handleToggleContent2 = () => {
    setShowContent2(!showContent2);
  };

  return (
    <div className="infoIconsContainer">
      <div className="infoIconContainer">
        <LazyImage
          srcPng={infoLogo}
          srcWebp={infoLogoWebp}
          alt="Information Icon"
          className="infoIcon infoIcon1"
          onClick={handleTogglePanel1}
          ref={iconRef1}
          priorityLevel={1}
        />
        <div className="relativeContainer relativeContainer1" ref={panelRef1}>
          <div className="hoverImg hoverImgContainer infoPanel1">
            <LazyImage
              srcPng={infoPanel1}
              srcWebp={infoPanel1Webp}
              alt="Information Panel"
              className="imgInfoPanel1"
              onClick={handleToggleContent1}
              priorityLevel={1}
            />
          </div>
          <div
            className="buttonPanel buttonPanel1"
            onClick={handleToggleContent1}
          >
            {texts[lang].loadingPage.howToInterractWebsite}
          </div>
          <div className="hoverContent hoverContent1">
            <div className="hoverImg hoverImgContainer infoPanelHighlight1">
              <LazyImage
                srcPng={infoPanelHighlight}
                srcWebp={infoPanelHighlightWebp}
                alt="Information Panel Highlight"
                className="imgInfoPanelHighlight1"
                priorityLevel={1}
              />
            </div>
            <div className="hoverImg hoverImgContainer redArrow2">
              <LazyImage
                srcPng={redArrow2}
                srcWebp={redArrow2Webp}
                alt="Red Arrow 2"
                className="imgRedArrow2"
                priorityLevel={1}
              />
            </div>
            <div className="hoverText hoverText1a">
              {texts[lang].loadingPage.minimap}
            </div>
            <div className="hoverImg hoverImgContainer redArrow1">
              <LazyImage
                srcPng={redArrow1}
                srcWebp={redArrow1Webp}
                alt="Red Arrow 1"
                className="imgRedArrow1"
                priorityLevel={1}
              />
            </div>
            <div className="hoverImg hoverImgContainer discoveryLogo">
              <LazyImage
                srcPng={discoveryLogo}
                srcWebp={discoveryLogoWebp}
                alt="Discovery Logo"
                className="imgDiscoveryLogo"
                priorityLevel={1}
              />
            </div>
            <div className="expandLogo">
              <FullscreenToggleButton />
            </div>
            <div className="langLogo">
              <LanguageSwitcher />
            </div>
            <div className="hoverImg hoverImgContainer musicLogo">
              <LazyImage
                srcPng={musicLogo}
                srcWebp={musicLogoWebp}
                src={musicLogo}
                alt="Music Logo"
                className="imgMusicLogo"
                priorityLevel={1}
              />
            </div>
            <div className="hoverText hoverText1b">
              {texts[lang].loadingPage.discovery}
            </div>
            <div className="hoverText hoverText1c">
              {texts[lang].loadingPage.expand}
            </div>
            <div className="hoverText hoverText1d">
              {texts[lang].loadingPage.lang}
            </div>
            <div className="hoverText hoverText1e">
              {texts[lang].loadingPage.music}
            </div>
          </div>
        </div>
      </div>

      <div className="infoIconContainer">
        <LazyImage
          srcPng={infoLogo2}
          srcWebp={infoLogo2Webp}
          alt="Information Icon 2"
          className="infoIcon infoIcon2"
          onClick={handleTogglePanel2}
          priorityLevel={1}
          ref={iconRef2}
        />
        <div className="relativeContainer relativeContainer2" ref={panelRef2}>
          <div className="hoverImg hoverImgContainer infoPanel2">
            <LazyImage
              srcPng={infoPanel2}
              srcWebp={infoPanel2Webp}
              alt="Information Panel 2"
              className="imgInfoPanel2"
              onClick={handleToggleContent2}
              priorityLevel={1}
            />
          </div>
          <div
            className="buttonPanel buttonPanel2"
            onClick={handleToggleContent2}
          >
            {texts[lang].loadingPage.howToInterractCharacter}
          </div>
          <div className="hoverContent hoverContent2">
            <div className="hoverImg hoverImgContainer redArrow3">
              <LazyImage
                srcPng={redArrow3}
                srcWebp={redArrow3Webp}
                alt="Red Arrow 3"
                className="imgRedArrow3"
                priorityLevel={1}
              />
            </div>
            <div className="hoverImg hoverImgContainer infoPanelHighlight2">
              <LazyImage
                srcPng={infoPanelHighlight2}
                srcWebp={infoPanelHighlight2Webp}
                alt="Information Panel Highlight"
                className="imgInfoPanelHighlight2"
                priorityLevel={1}
              />
            </div>
            <div className="hoverImg hoverImgContainer characterLoading">
              <LazyImage
                srcPng={characterLoading}
                srcWebp={characterLoadingWebp}
                alt="Character"
                className="imgCharacterLoading"
                priorityLevel={1}
              />
            </div>
            <div className="hoverImg hoverImgContainer redArrow6">
              <LazyImage
                srcPng={redArrow6}
                srcWebp={redArrow6Webp}
                alt="Red Arrow 6"
                className="imgRedArrow6"
                priorityLevel={1}
              />
            </div>
            <div className="hoverText hoverText2c">
              {texts[lang].loadingPage.nextSlide}
            </div>
            <div className="hoverImg hoverImgContainer redArrow4">
              <LazyImage
                srcPng={redArrow4}
                srcWebp={redArrow4Webp}
                alt="Red Arrow 4"
                className="imgRedArrow4"
                priorityLevel={1}
              />
            </div>
            <div className="hoverText hoverText2a">
              {texts[lang].loadingPage.seeSection}
            </div>
            <div className="hoverText hoverText2b">
              {texts[lang].loadingPage.closeBubble}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoIcon;
