// Vérifie si gtag est initialisé et disponible
const isGtagAvailable = () => typeof window !== "undefined" && window.gtag;

// Fonction pour enregistrer un événement personnalisé
export const logEvent = (category, action, label = "", value = 0) => {
  if (isGtagAvailable()) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  } else {
    console.warn("GA4 is not initialized properly or gtag not available.");
  }
};

// Fonction pour enregistrer une vue de page
export const logPageView = (
  path = window.location.pathname + window.location.search
) => {
  if (isGtagAvailable()) {
    window.gtag("event", "page_view", {
      page_path: path,
    });
  } else {
    console.warn("GA4 is not initialized properly or gtag not available.");
  }
};

// Fonction debounce pour éviter les événements en double
const debounceEvent = (func, delay = 300) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

// Fonction pour enregistrer les clics sur les boutons avec debounce
export const logButtonClick = debounceEvent((category, action, label) => {
  logEvent(category, action, label);
}, 500);
