import React, { useContext, useState } from "react";
import emailjs from "emailjs-com";
import { texts } from "@data/textsData";
import "./ContactForm.css";
import { LangContext } from "@context/LangContext";

const ContactForm = () => {
  const { lang } = useContext(LangContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name,
      email,
      subject,
      message,
    };

    emailjs
      .send(
        "service_32k7alh",
        "template_fi0ezfj",
        templateParams,
        "Bcr3Cz-hkl_iFNeNa"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage(texts[lang].contactForm.successMessage);
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setTimeout(() => setSuccessMessage(""), 3000);
        },
        (err) => {
          console.log("FAILED...", err);
          setErrorMessage(texts[lang].contactForm.errorMessage);
          setTimeout(() => setErrorMessage(""), 3000);
        }
      );
  };

  return (
    <div className="contactFormContainer">
      <form className="contactForm" onSubmit={handleSubmit}>
        <h2>{texts[lang].contactForm.title}</h2>

        <div className="formRow">
          <div className="formGroup formGroupHalf">
            <input
              type="text"
              id="name"
              placeholder={texts[lang].contactForm.placeholderName}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              minLength="2"
            />
          </div>
          <div className="formGroup formGroupHalf">
            <input
              type="email"
              id="email"
              placeholder={texts[lang].contactForm.placeholderMail}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="formGroup formGroupFull">
          <input
            type="text"
            id="subject"
            placeholder={texts[lang].contactForm.placeholderObject}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            minLength="5"
          />
        </div>

        <div className="formGroup formGroupFull">
          <textarea
            id="message"
            placeholder={texts[lang].contactForm.placeholderMessage}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            minLength="50"
          ></textarea>
        </div>

        <button type="submit" className="submitButton">
          {texts[lang].contactForm.buttonSend}
        </button>
      </form>

      {(successMessage || errorMessage) && (
        <div className={`overlay ${successMessage ? "success" : "error"}`}>
          <div className="overlayContent">
            <p>{successMessage || errorMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
