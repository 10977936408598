// @components/CharacterAnimated.js

import React, { useEffect, useRef, useState, useContext } from "react";
import { gsap } from "gsap";
import { LangContext } from "@context/LangContext";
import { texts } from "@data/textsData";
import "./CharacterAnimated.css";
import LazyImage from "@components/LazyImage";

// PNG IMG
import character1Body from "@assets/charactersAssets/character1Body.png";
import character1Head from "@assets/charactersAssets/character1Head.png";
import character1LeftArm from "@assets/charactersAssets/character1LeftArm.png";
import character1RightArm from "@assets/charactersAssets/character1RightArm.png";
import character2Body from "@assets/charactersAssets/character2Body.png";
import character2Head from "@assets/charactersAssets/character2Head.png";
import character2LeftArm from "@assets/charactersAssets/character2LeftArm.png";
import character2RightArm from "@assets/charactersAssets/character2RightArm.png";

// Web IMG
import character1BodyWebp from "@assets/charactersAssets/character1Body.webp";
import character1HeadWebp from "@assets/charactersAssets/character1Head.webp";
import character1LeftArmWebp from "@assets/charactersAssets/character1LeftArm.webp";
import character1RightArmWebp from "@assets/charactersAssets/character1RightArm.webp";
import character2BodyWebp from "@assets/charactersAssets/character2Body.webp";
import character2HeadWebp from "@assets/charactersAssets/character2Head.webp";
import character2LeftArmWebp from "@assets/charactersAssets/character2LeftArm.webp";
import character2RightArmWebp from "@assets/charactersAssets/character2RightArm.webp";

const CharacterAnimated = ({ orientation }) => {
  const bodyRef = useRef(null);
  const headRef = useRef(null);
  const leftArmRef = useRef(null);
  const rightArmRef = useRef(null);
  const { lang } = useContext(LangContext);
  const [hoverMessage, setHoverMessage] = useState("");
  const [hoverVisible, setHoverVisible] = useState(false);

  const getCharacterParts = (orientation) => {
    if (orientation === "left") {
      return {
        bodyUrl: character1Body,
        headUrl: character1Head,
        leftArmUrl: character1LeftArm,
        rightArmUrl: character1RightArm,
        bodyUrlWebp: character1BodyWebp,
        headUrlWebp: character1HeadWebp,
        leftArmUrlWebp: character1LeftArmWebp,
        rightArmUrlWebp: character1RightArmWebp,
        leftArmClass: "left-arm-character1",
        rightArmClass: "right-arm-character1",
      };
    } else {
      return {
        bodyUrl: character2Body,
        headUrl: character2Head,
        leftArmUrl: character2LeftArm,
        rightArmUrl: character2RightArm,
        bodyUrlWebp: character2BodyWebp,
        headUrlWebp: character2HeadWebp,
        leftArmUrlWebp: character2LeftArmWebp,
        rightArmUrlWebp: character2RightArmWebp,
        leftArmClass: "left-arm-character2",
        rightArmClass: "right-arm-character2",
      };
    }
  };

  const {
    bodyUrl,
    headUrl,
    leftArmUrl,
    rightArmUrl,
    bodyUrlWebp,
    headUrlWebp,
    leftArmUrlWebp,
    rightArmUrlWebp,
    leftArmClass,
    rightArmClass,
  } = getCharacterParts(orientation);

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, yoyo: true });

    // Animation logic for the left arm
    tl.to(leftArmRef.current, {
      rotation: 15,
      transformOrigin: "top left",
      duration: 1,
      ease: "power1.inOut",
    }).to(leftArmRef.current, {
      rotation: -15,
      transformOrigin: "top left",
      duration: 1,
      ease: "power1.inOut",
    });

    // Animation logic for the right arm with a slight delay and variation
    const rightArmTl = gsap.timeline({ repeat: -1, yoyo: true });
    rightArmTl
      .to(rightArmRef.current, {
        rotation: 10,
        transformOrigin: "top right",
        duration: 1,
        ease: "power1.inOut",
      })
      .to(rightArmRef.current, {
        rotation: -10,
        transformOrigin: "top right",
        duration: 1,
        ease: "power1.inOut",
      });

    // Animation logic for the head
    const headTl = gsap.timeline({ repeat: -1, yoyo: true });
    headTl
      .to(headRef.current, {
        rotation: 5,
        transformOrigin: "center",
        duration: 1.5,
        ease: "power1.inOut",
      })
      .to(headRef.current, {
        rotation: -5,
        transformOrigin: "center",
        duration: 1.5,
        ease: "power1.inOut",
      })
      .to(headRef.current, {
        rotation: 3,
        transformOrigin: "center",
        duration: 1.5,
        ease: "power1.inOut",
      })
      .to(headRef.current, {
        rotation: -3,
        transformOrigin: "center",
        duration: 1.5,
        ease: "power1.inOut",
      });

    // Animation logic for the body
    tl.to(
      bodyRef.current,
      {
        rotation: 5,
        transformOrigin: "center",
        duration: 1,
        ease: "power1.inOut",
      },
      0
    ).to(
      bodyRef.current,
      {
        rotation: -5,
        transformOrigin: "center",
        duration: 1,
        ease: "power1.inOut",
      },
      1
    );

    // Combine timelines with a slight delay for the right arm
    gsap.delayedCall(0.5, () => rightArmTl.play());
  }, []);

  const selectRandomHoverMessage = () => {
    const randomMessage =
      texts[lang]?.hovers?.hoverMessages[
        Math.floor(Math.random() * texts[lang]?.hovers?.hoverMessages.length)
      ];
    setHoverMessage(randomMessage);
  };

  return (
    <div
      className={`character-animated ${orientation}`}
      onMouseEnter={() => {
        selectRandomHoverMessage();
        setHoverVisible(true);
      }}
      onMouseLeave={() => setHoverVisible(false)}
    >
      <div className="character-image body">
        <LazyImage
          srcPng={bodyUrl}
          srcWebp={bodyUrlWebp}
          alt="Character Body"
          className="bodyImg"
          ref={bodyRef}
          priorityLevel={2}
        />
      </div>
      <div className="character-image head">
        <LazyImage
          srcPng={headUrl}
          srcWebp={headUrlWebp}
          alt="Character Head"
          className="headImg"
          ref={headRef}
          priorityLevel={2}
        />
      </div>

      <div className={`character-image ${leftArmClass}`}>
        <LazyImage
          srcPng={leftArmUrl}
          srcWebp={leftArmUrlWebp}
          alt="Character Left Arm"
          className={`${leftArmClass}Img`}
          ref={leftArmRef}
          priorityLevel={2}
        />
      </div>

      <div className={`character-image ${rightArmClass}`}>
        <LazyImage
          srcPng={rightArmUrl}
          srcWebp={rightArmUrlWebp}
          alt="Character Right Arm"
          className={`${rightArmClass}Img`}
          ref={rightArmRef}
          priorityLevel={2}
        />
      </div>

      {hoverVisible && (
        <div className="character-hover-text">{hoverMessage}</div>
      )}
    </div>
  );
};

export default CharacterAnimated;
