import React, { createContext, useState, useCallback, useEffect } from "react";
import { charactersData } from "@data/charactersData";

export const BubbleContext = createContext();

export const BubbleProvider = ({ children }) => {
  const [minimizedBubbles, setMinimizedBubbles] = useState({});

  useEffect(() => {
    // Initialisation de l'état des bulles avec les identifiants des personnages
    const initialBubbles = charactersData.reduce((acc, character) => {
      acc[character.id] = false; // par défaut
      return acc;
    }, {});
    setMinimizedBubbles(initialBubbles);
  }, []);

  const minimizeAllBubbles = useCallback(() => {
    setMinimizedBubbles((prevState) => {
      const allMinimized = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      return allMinimized;
    });
  }, []);

  const toggleBubble = useCallback((id) => {
    setMinimizedBubbles((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);

  return (
    <BubbleContext.Provider
      value={{ minimizedBubbles, minimizeAllBubbles, toggleBubble }}
    >
      {children}
    </BubbleContext.Provider>
  );
};
