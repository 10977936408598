// png imports
import montgolfiere1 from "@assets/blueSkyAssets/montgolfiere1.png";
import montgolfiere2 from "@assets/blueSkyAssets/montgolfiere2.png";
import circleCloud1 from "@assets/blueSkyAssets/circleClouds/circleCloud1.png";
import circleCloud2 from "@assets/blueSkyAssets/circleClouds/circleCloud2.png";
import circleCloud3 from "@assets/blueSkyAssets/circleClouds/circleCloud3.png";
import circleCloud4 from "@assets/blueSkyAssets/circleClouds/circleCloud4.png";
import circleCloud5 from "@assets/blueSkyAssets/circleClouds/circleCloud5.png";
import circleCloud6 from "@assets/blueSkyAssets/circleClouds/circleCloud6.png";
import circleCloud7 from "@assets/blueSkyAssets/circleClouds/circleCloud7.png";
import circleCloud8 from "@assets/blueSkyAssets/circleClouds/circleCloud8.png";
import circleCloud9 from "@assets/blueSkyAssets/circleClouds/circleCloud9.png";
import circleCloud10 from "@assets/blueSkyAssets/circleClouds/circleCloud10.png";
import circleCloud11 from "@assets/blueSkyAssets/circleClouds/circleCloud11.png";
import circleCloud12 from "@assets/blueSkyAssets/circleClouds/circleCloud12.png";
import circleCloud13 from "@assets/blueSkyAssets/circleClouds/circleCloud12.png";
import circleCloud14 from "@assets/blueSkyAssets/circleClouds/circleCloud14.png";
import circleCloud15 from "@assets/blueSkyAssets/circleClouds/circleCloud9.png";
import circleCloud16 from "@assets/blueSkyAssets/circleClouds/circleCloud11.png";

import city1 from "@assets/blueSkyAssets/city1.png";
import city2 from "@assets/blueSkyAssets/city2.png";
import city3 from "@assets/blueSkyAssets/city3.png";
import city4 from "@assets/blueSkyAssets/city4.png";
import city5 from "@assets/blueSkyAssets/city5.png";
import flyingRock1 from "@assets/blueSkyAssets/flyingRock1.png";
import flyingRock2 from "@assets/blueSkyAssets/flyingRock2.png";
import flyingRock3 from "@assets/blueSkyAssets/flyingRock3.png";
import flyingRock4 from "@assets/blueSkyAssets/flyingRock4.png";
import flyingRock5 from "@assets/blueSkyAssets/flyingRock5.png";
import flyingRock6 from "@assets/blueSkyAssets/flyingRock6.png";
import flyingRock7 from "@assets/blueSkyAssets/flyingRock7.png";
import flyingRock8 from "@assets/blueSkyAssets/flyingRock8.png";
import flyingRock9 from "@assets/blueSkyAssets/flyingRock9.png";
import flyingRock10 from "@assets/blueSkyAssets/flyingRock7.png";
import flyingRock11 from "@assets/blueSkyAssets/flyingRock8.png";
import flyingRock12 from "@assets/blueSkyAssets/flyingRock9.png";

import skyShip1 from "@assets/blueSkyAssets/skyship1.png";
import skyShip2 from "@assets/blueSkyAssets/skyship2.png";
import skyShip3 from "@assets/blueSkyAssets/skyship3.png";
import skyShip4 from "@assets/blueSkyAssets/skyship4.png";
import skyShip5 from "@assets/blueSkyAssets/skyship5.png";
import skyShip6 from "@assets/blueSkyAssets/skyship6.png";
import skyShip7 from "@assets/blueSkyAssets/skyship7.png";
import skyShip8 from "@assets/blueSkyAssets/skyship8.png";
import skyShip9 from "@assets/blueSkyAssets/skyship9.png";
import skyShip10 from "@assets/blueSkyAssets/skyship10.png";
import skyShip11 from "@assets/blueSkyAssets/skyship11.png";
import skyShip12 from "@assets/blueSkyAssets/skyship12.png";
import skyShip13 from "@assets/blueSkyAssets/skyship13.png";
import skyShip14 from "@assets/blueSkyAssets/skyship14.png";
import skyShip15 from "@assets/blueSkyAssets/skyship15.png";
import skyShip16 from "@assets/blueSkyAssets/skyship16.png";
import skyShip17 from "@assets/blueSkyAssets/skyship17.png";
import skyShip18 from "@assets/blueSkyAssets/skyship18.png";
import skyShip19 from "@assets/blueSkyAssets/skyship19.png";

import longCloud1 from "@assets/blueSkyAssets/longclouds/longCloud1.png";
import longCloud2 from "@assets/blueSkyAssets/longclouds/longCloud2.png";
import longCloud3 from "@assets/blueSkyAssets/longclouds/longCloud3.png";
import longCloud4 from "@assets/blueSkyAssets/longclouds/longCloud4.png";
import longCloud5 from "@assets/blueSkyAssets/longclouds/longCloud5.png";
import longCloud6 from "@assets/blueSkyAssets/longclouds/longCloud6.png";
import longCloud7 from "@assets/blueSkyAssets/longclouds/longCloud7.png";
import longCloud8 from "@assets/blueSkyAssets/longclouds/longCloud8.png";
import longCloud9 from "@assets/blueSkyAssets/longclouds/longCloud9.png";
import longCloud10 from "@assets/blueSkyAssets/longclouds/longCloud10.png";
import longCloud11 from "@assets/blueSkyAssets/longclouds/longCloud11.png";
import longCloud12 from "@assets/blueSkyAssets/longclouds/longCloud12.png";
import longCloud13 from "@assets/blueSkyAssets/longclouds/longCloud13.png";
import longCloud14 from "@assets/blueSkyAssets/longclouds/longCloud14.png";
import longCloud15 from "@assets/blueSkyAssets/longclouds/longCloud15.png";
import longCloud16 from "@assets/blueSkyAssets/longclouds/longCloud16.png";
import longCloud17 from "@assets/blueSkyAssets/longclouds/longCloud17.png";
import longCloud18 from "@assets/blueSkyAssets/longclouds/longCloud18.png";
import longCloud19 from "@assets/blueSkyAssets/longclouds/longCloud19.png";
import longCloud20 from "@assets/blueSkyAssets/longclouds/longCloud20.png";
import longCloud21 from "@assets/blueSkyAssets/longclouds/longCloud21.png";
import longCloud22 from "@assets/blueSkyAssets/longclouds/longCloud22.png";
import longCloud23 from "@assets/blueSkyAssets/longclouds/longCloud23.png";

import groundPart1 from "@assets/blueSkyAssets/groundParts/groundPart1.png";
import groundPart2 from "@assets/blueSkyAssets/groundParts/groundPart2.png";
import groundPart3 from "@assets/blueSkyAssets/groundParts/groundPart3.png";
import groundPart4 from "@assets/blueSkyAssets/groundParts/groundPart4.png";
import groundPart5 from "@assets/blueSkyAssets/groundParts/groundPart5.png";
import groundPart6 from "@assets/blueSkyAssets/groundParts/groundPart6.png";
import groundPart7 from "@assets/blueSkyAssets/groundParts/groundPart7.png";
import groundPart8 from "@assets/blueSkyAssets/groundParts/groundPart8.png";
import groundPart9 from "@assets/blueSkyAssets/groundParts/groundPart9.png";
import groundPart10 from "@assets/blueSkyAssets/groundParts/groundPart10.png";
import groundPart11 from "@assets/blueSkyAssets/groundParts/groundPart11.png";
import groundPart12 from "@assets/blueSkyAssets/groundParts/groundPart12.png";

// webP imports
import montgolfiere1Webp from "@assets/blueSkyAssets/montgolfiere1.webp";
import montgolfiere2Webp from "@assets/blueSkyAssets/montgolfiere2.webp";
import circleCloud1Webp from "@assets/blueSkyAssets/circleClouds/circleCloud1.webp";
import circleCloud2Webp from "@assets/blueSkyAssets/circleClouds/circleCloud2.webp";
import circleCloud3Webp from "@assets/blueSkyAssets/circleClouds/circleCloud3.webp";
import circleCloud4Webp from "@assets/blueSkyAssets/circleClouds/circleCloud4.webp";
import circleCloud5Webp from "@assets/blueSkyAssets/circleClouds/circleCloud5.webp";
import circleCloud6Webp from "@assets/blueSkyAssets/circleClouds/circleCloud6.webp";
import circleCloud7Webp from "@assets/blueSkyAssets/circleClouds/circleCloud7.webp";
import circleCloud8Webp from "@assets/blueSkyAssets/circleClouds/circleCloud8.webp";
import circleCloud9Webp from "@assets/blueSkyAssets/circleClouds/circleCloud9.webp";
import circleCloud10Webp from "@assets/blueSkyAssets/circleClouds/circleCloud10.webp";
import circleCloud11Webp from "@assets/blueSkyAssets/circleClouds/circleCloud11.webp";
import circleCloud12Webp from "@assets/blueSkyAssets/circleClouds/circleCloud12.webp";
import circleCloud13Webp from "@assets/blueSkyAssets/circleClouds/circleCloud12.webp";
import circleCloud14Webp from "@assets/blueSkyAssets/circleClouds/circleCloud14.webp";
import circleCloud15Webp from "@assets/blueSkyAssets/circleClouds/circleCloud9.webp";
import circleCloud16Webp from "@assets/blueSkyAssets/circleClouds/circleCloud11.webp";
import city1Webp from "@assets/blueSkyAssets/city1.webp";
import city2Webp from "@assets/blueSkyAssets/city2.webp";
import city3Webp from "@assets/blueSkyAssets/city3.webp";
import city4Webp from "@assets/blueSkyAssets/city4.webp";
import city5Webp from "@assets/blueSkyAssets/city5.webp";
import flyingRock1Webp from "@assets/blueSkyAssets/flyingRock1.webp";
import flyingRock2Webp from "@assets/blueSkyAssets/flyingRock2.webp";
import flyingRock3Webp from "@assets/blueSkyAssets/flyingRock3.webp";
import flyingRock4Webp from "@assets/blueSkyAssets/flyingRock4.webp";
import flyingRock5Webp from "@assets/blueSkyAssets/flyingRock5.webp";
import flyingRock6Webp from "@assets/blueSkyAssets/flyingRock6.webp";
import flyingRock7Webp from "@assets/blueSkyAssets/flyingRock7.webp";
import flyingRock8Webp from "@assets/blueSkyAssets/flyingRock8.webp";
import flyingRock9Webp from "@assets/blueSkyAssets/flyingRock9.webp";
import flyingRock10Webp from "@assets/blueSkyAssets/flyingRock7.webp";
import flyingRock11Webp from "@assets/blueSkyAssets/flyingRock8.webp";
import flyingRock12Webp from "@assets/blueSkyAssets/flyingRock9.webp";
import skyShip1Webp from "@assets/blueSkyAssets/skyship1.webp";
import skyShip2Webp from "@assets/blueSkyAssets/skyship2.webp";
import skyShip3Webp from "@assets/blueSkyAssets/skyship3.webp";
import skyShip4Webp from "@assets/blueSkyAssets/skyship4.webp";
import skyShip5Webp from "@assets/blueSkyAssets/skyship5.webp";
import skyShip6Webp from "@assets/blueSkyAssets/skyship6.webp";
import skyShip7Webp from "@assets/blueSkyAssets/skyship7.webp";
import skyShip8Webp from "@assets/blueSkyAssets/skyship8.webp";
import skyShip9Webp from "@assets/blueSkyAssets/skyship9.webp";
import skyShip10Webp from "@assets/blueSkyAssets/skyship10.webp";
import skyShip11Webp from "@assets/blueSkyAssets/skyship11.webp";
import skyShip12Webp from "@assets/blueSkyAssets/skyship12.webp";
import skyShip13Webp from "@assets/blueSkyAssets/skyship13.webp";
import skyShip14Webp from "@assets/blueSkyAssets/skyship14.webp";
import skyShip15Webp from "@assets/blueSkyAssets/skyship15.webp";
import skyShip16Webp from "@assets/blueSkyAssets/skyship16.webp";
import skyShip17Webp from "@assets/blueSkyAssets/skyship17.webp";
import skyShip18Webp from "@assets/blueSkyAssets/skyship18.webp";
import skyShip19Webp from "@assets/blueSkyAssets/skyship19.webp";
import longCloud1Webp from "@assets/blueSkyAssets/longclouds/longCloud1.webp";
import longCloud2Webp from "@assets/blueSkyAssets/longclouds/longCloud2.webp";
import longCloud3Webp from "@assets/blueSkyAssets/longclouds/longCloud3.webp";
import longCloud4Webp from "@assets/blueSkyAssets/longclouds/longCloud4.webp";
import longCloud5Webp from "@assets/blueSkyAssets/longclouds/longCloud5.webp";
import longCloud6Webp from "@assets/blueSkyAssets/longclouds/longCloud6.webp";
import longCloud7Webp from "@assets/blueSkyAssets/longclouds/longCloud7.webp";
import longCloud8Webp from "@assets/blueSkyAssets/longclouds/longCloud8.webp";
import longCloud9Webp from "@assets/blueSkyAssets/longclouds/longCloud9.webp";
import longCloud10Webp from "@assets/blueSkyAssets/longclouds/longCloud10.webp";
import longCloud11Webp from "@assets/blueSkyAssets/longclouds/longCloud11.webp";
import longCloud12Webp from "@assets/blueSkyAssets/longclouds/longCloud12.webp";
import longCloud13Webp from "@assets/blueSkyAssets/longclouds/longCloud13.webp";
import longCloud14Webp from "@assets/blueSkyAssets/longclouds/longCloud14.webp";
import longCloud15Webp from "@assets/blueSkyAssets/longclouds/longCloud15.webp";
import longCloud16Webp from "@assets/blueSkyAssets/longclouds/longCloud16.webp";
import longCloud17Webp from "@assets/blueSkyAssets/longclouds/longCloud17.webp";
import longCloud18Webp from "@assets/blueSkyAssets/longclouds/longCloud18.webp";
import longCloud19Webp from "@assets/blueSkyAssets/longclouds/longCloud19.webp";
import longCloud20Webp from "@assets/blueSkyAssets/longclouds/longCloud20.webp";
import longCloud21Webp from "@assets/blueSkyAssets/longclouds/longCloud21.webp";
import longCloud22Webp from "@assets/blueSkyAssets/longclouds/longCloud22.webp";
import longCloud23Webp from "@assets/blueSkyAssets/longclouds/longCloud23.webp";
import groundPart1Webp from "@assets/blueSkyAssets/groundParts/groundPart1.webp";
import groundPart2Webp from "@assets/blueSkyAssets/groundParts/groundPart2.webp";
import groundPart3Webp from "@assets/blueSkyAssets/groundParts/groundPart3.webp";
import groundPart4Webp from "@assets/blueSkyAssets/groundParts/groundPart4.webp";
import groundPart5Webp from "@assets/blueSkyAssets/groundParts/groundPart5.webp";
import groundPart6Webp from "@assets/blueSkyAssets/groundParts/groundPart6.webp";
import groundPart7Webp from "@assets/blueSkyAssets/groundParts/groundPart7.webp";
import groundPart8Webp from "@assets/blueSkyAssets/groundParts/groundPart8.webp";
import groundPart9Webp from "@assets/blueSkyAssets/groundParts/groundPart9.webp";
import groundPart10Webp from "@assets/blueSkyAssets/groundParts/groundPart10.webp";
import groundPart11Webp from "@assets/blueSkyAssets/groundParts/groundPart11.webp";
import groundPart12Webp from "@assets/blueSkyAssets/groundParts/groundPart12.webp";

export const imagesBlueSky = {
  montgolfiere1,
  montgolfiere2,
  circleCloud1,
  circleCloud2,
  circleCloud3,
  circleCloud4,
  circleCloud5,
  circleCloud6,
  circleCloud7,
  circleCloud8,
  circleCloud9,
  circleCloud10,
  circleCloud11,
  circleCloud12,
  circleCloud13,
  circleCloud14,
  circleCloud15,
  circleCloud16,

  city1,
  city2,
  city3,
  city4,
  city5,
  flyingRock1,
  flyingRock2,
  flyingRock3,
  flyingRock4,
  flyingRock5,
  flyingRock6,
  flyingRock7,
  flyingRock8,
  flyingRock9,
  flyingRock10,
  flyingRock11,
  flyingRock12,
  skyShip1,
  skyShip2,
  skyShip3,
  skyShip4,
  skyShip5,
  skyShip6,
  skyShip7,
  skyShip8,
  skyShip9,
  skyShip10,
  skyShip11,
  skyShip12,
  skyShip13,
  skyShip14,
  skyShip15,
  skyShip16,
  skyShip17,
  skyShip18,
  skyShip19,
  longCloud1,
  longCloud2,
  longCloud3,
  longCloud4,
  longCloud5,
  longCloud6,
  longCloud7,
  longCloud8,
  longCloud9,
  longCloud10,
  longCloud11,
  longCloud12,
  longCloud13,
  longCloud14,
  longCloud15,
  longCloud16,
  longCloud17,
  longCloud18,
  longCloud19,
  longCloud20,
  longCloud21,
  longCloud22,
  longCloud23,
  groundPart1,
  groundPart2,
  groundPart3,
  groundPart4,
  groundPart5,
  groundPart6,
  groundPart7,
  groundPart8,
  groundPart9,
  groundPart10,
  groundPart11,
  groundPart12,
};

export const imagesBlueSkyWebp = {
  montgolfiere1Webp,
  montgolfiere2Webp,
  circleCloud1Webp,
  circleCloud2Webp,
  circleCloud3Webp,
  circleCloud4Webp,
  circleCloud5Webp,
  circleCloud6Webp,
  circleCloud7Webp,
  circleCloud8Webp,
  circleCloud9Webp,
  circleCloud10Webp,
  circleCloud11Webp,
  circleCloud12Webp,
  circleCloud13Webp,
  circleCloud14Webp,
  circleCloud15Webp,
  circleCloud16Webp,

  city1Webp,
  city2Webp,
  city3Webp,
  city4Webp,
  city5Webp,
  flyingRock1Webp,
  flyingRock2Webp,
  flyingRock3Webp,
  flyingRock4Webp,
  flyingRock5Webp,
  flyingRock6Webp,
  flyingRock7Webp,
  flyingRock8Webp,
  flyingRock9Webp,
  flyingRock10Webp,
  flyingRock11Webp,
  flyingRock12Webp,
  skyShip1Webp,
  skyShip2Webp,
  skyShip3Webp,
  skyShip4Webp,
  skyShip5Webp,
  skyShip6Webp,
  skyShip7Webp,
  skyShip8Webp,
  skyShip9Webp,
  skyShip10Webp,
  skyShip11Webp,
  skyShip12Webp,
  skyShip13Webp,
  skyShip14Webp,
  skyShip15Webp,
  skyShip16Webp,
  skyShip17Webp,
  skyShip18Webp,
  skyShip19Webp,
  longCloud1Webp,
  longCloud2Webp,
  longCloud3Webp,
  longCloud4Webp,
  longCloud5Webp,
  longCloud6Webp,
  longCloud7Webp,
  longCloud8Webp,
  longCloud9Webp,
  longCloud10Webp,
  longCloud11Webp,
  longCloud12Webp,
  longCloud13Webp,
  longCloud14Webp,
  longCloud15Webp,
  longCloud16Webp,
  longCloud17Webp,
  longCloud18Webp,
  longCloud19Webp,
  longCloud20Webp,
  longCloud21Webp,
  longCloud22Webp,
  longCloud23Webp,
  groundPart1Webp,
  groundPart2Webp,
  groundPart3Webp,
  groundPart4Webp,
  groundPart5Webp,
  groundPart6Webp,
  groundPart7Webp,
  groundPart8Webp,
  groundPart9Webp,
  groundPart10Webp,
  groundPart11Webp,
  groundPart12Webp,
};

export const circleClouds = [
  "circleCloud1",
  "circleCloud2",
  "circleCloud3",
  "circleCloud4",
  "circleCloud5",
  "circleCloud6",
  "circleCloud7",
  "circleCloud8",
  "circleCloud9",
  "circleCloud10",
  "circleCloud11",
  "circleCloud12",
  "circleCloud13",
  "circleCloud14",
  "circleCloud15",
  "circleCloud16",
];

export const cities = ["city1", "city2", "city3", "city4", "city5"];

export const flyingRocks = [
  "flyingRock1",
  "flyingRock2",
  "flyingRock3",
  "flyingRock4",
  "flyingRock5",
  "flyingRock6",
  "flyingRock7",
  "flyingRock8",
  "flyingRock9",
  "flyingRock10",
  "flyingRock11",
  "flyingRock12",
];

export const skyShips = [
  "skyShip1",
  "skyShip2",
  "skyShip3",
  "skyShip4",
  "skyShip5",
  "skyShip6",
  "skyShip7",
  "skyShip8",
  "skyShip9",
  "skyShip10",
  "skyShip11",
  "skyShip12",
  "skyShip13",
  "skyShip14",
  "skyShip15",
  "skyShip16",
  "skyShip17",
  "skyShip18",
  "skyShip19",
];

export const montgolfieres = ["montgolfiere1", "montgolfiere2"];

export const longClouds = [
  "longCloud1",
  "longCloud2",
  "longCloud3",
  "longCloud4",
  "longCloud5",
  "longCloud6",
  "longCloud7",
  "longCloud8",
  "longCloud9",
  "longCloud10",
  "longCloud11",
  "longCloud12",
  "longCloud13",
  "longCloud14",
  "longCloud15",
  "longCloud16",
  "longCloud17",
  "longCloud18",
  "longCloud19",
  "longCloud20",
  "longCloud21",
  "longCloud22",
  "longCloud23",
];

export const groundParts = [
  "groundPart1",
  "groundPart2",
  "groundPart3",
  "groundPart4",
  "groundPart5",
  "groundPart6",
  "groundPart7",
  "groundPart8",
  "groundPart9",
  "groundPart10",
  "groundPart11",
  "groundPart12",
];
