import React from "react";
import { Helmet } from "react-helmet-async";
import "./MainContent.css";
import CompleteSection from "@components/completeSection/CompleteSection";

const MainContent = () => (
  <main>
    <Helmet>
      <title>The Scrum Nomad</title>
      <meta
        name="description"
        content="Join The Scrum Nomad on a journey of discovery, exploring agile practices and Scrum through engaging articles and an interactive portfolio."
      />
      <link rel="canonical" href="https://TheScrumNomad.com/" />
      <meta property="og:title" content="The Scrum Nomad" />
      <meta
        property="og:description"
        content="Join The Scrum Nomad on a journey of discovery, exploring agile practices and Scrum through engaging articles and an interactive portfolio."
      />
      <meta property="og:image" content="/images/og-image.png" />
      <meta property="og:url" content="https://TheScrumNomad.com/" />
      <meta name="twitter:title" content="The Scrum Nomad" />
      <meta
        name="twitter:description"
        content="Join The Scrum Nomad on a journey of discovery, exploring agile practices and Scrum through engaging articles and an interactive portfolio."
      />
      <meta name="twitter:image" content="/images/twitter-image.png" />
    </Helmet>
    <CompleteSection />
  </main>
);

export default MainContent;
