import React from "react";
import "./NotFound.css";
import backgroundImage from "./notFoundAssets/notFoundBackground.png";
import buildingSign from "./notFoundAssets/buildingSign.png";

const NotFound = () => {
  return (
    <div className="not-found">
      <img className="backgroundImage" src={backgroundImage} alt="" />
      <img className="buildingSign" src={buildingSign} alt="" />
    </div>
  );
};

export default NotFound;
