import React, { useState, useContext } from "react";
import "./Minimap.css";
import minimapSrc from "@assets/minimapAssets/completeBackgroundSideImage.jpg";
import minimapSrcWebp from "@assets/minimapAssets/completeBackgroundSideImage.webp";

import { LangContext } from "@context/LangContext";
import { texts } from "@data/textsData";
import { minimapPointsData } from "@data/minimapPointsData";
import LazyImage from "@components/LazyImage";
import { logButtonClick } from "@utils/analytics";

const Minimap = () => {
  const { lang } = useContext(LangContext);
  const [hoverTextMinimap, setHoverTextMinimap] = useState("");
  const [hoverTextMinimapStyle, setHoverTextMinimapStyle] = useState({
    display: "none",
  });
  const [hoverTextPoint, setHoverTextPoint] = useState("");
  const [hoverTextPointStyle, setHoverTextPointStyle] = useState({
    display: "none",
  });

  const handleMouseEnterMinimap = (event) => {
    setHoverTextMinimap(texts[lang].minimap.backgroundHover);
    setHoverTextMinimapStyle({ display: "block" });
    setHoverTextMinimapStyle({
      display: "block",
      top: `${event.clientY - 10}px`,
      left: `${event.clientX + 10}px`,
    });
  };

  const handleMouseLeaveMinimap = () => {
    setHoverTextMinimap("");
    setHoverTextMinimapStyle({ display: "none" });
  };

  const handleMouseMoveMinimap = (event) => {
    setHoverTextMinimapStyle({
      display: "block",
      top: `${event.clientY + 30}px`,
      left: `${event.clientX - 60}px`,
    });
  };

  const handlePointMouseEnter = (event, name) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const minimapRect =
      event.currentTarget.parentElement.getBoundingClientRect();
    setHoverTextPoint(name);
    setHoverTextPointStyle({
      display: "block",
      top: `${rect.top - minimapRect.top - 30}px`,
      left: `${rect.left - minimapRect.left - 90}px`,
    });
  };

  const handlePointMouseLeave = () => {
    setHoverTextPoint("");
    setHoverTextPointStyle({ display: "none" });
  };

  const handlePointClick = (position, pointName) => {
    logButtonClick("Minimap", "Click", pointName);

    const targetPosition = (position / 100) * document.body.scrollHeight;
    window.scrollTo({
      top: targetPosition - 250,
      behavior: "smooth",
    });
  };

  return (
    <div className="minimap-container">
      <div
        className="minimap"
        onMouseEnter={handleMouseEnterMinimap}
        onMouseLeave={handleMouseLeaveMinimap}
        onMouseMove={handleMouseMoveMinimap}
      >
        <LazyImage
          srcPng={minimapSrc}
          srcWebp={minimapSrcWebp}
          alt="Minimap"
          priorityLevel={3}
        />
        {minimapPointsData.map((point, index) => (
          <div
            key={point.id}
            className={`minimap-point ${point.name}`}
            style={{
              top: `${point.position}%`,
              backgroundColor: point.color,
              "--point-color": point.color,
            }}
            onMouseEnter={(event) =>
              handlePointMouseEnter(
                event,
                texts[lang].minimap[`hover${index + 1}`]
              )
            }
            onMouseLeave={handlePointMouseLeave}
            onClick={() => handlePointClick(point.position, point.name)}
          ></div>
        ))}
        <div className="minimap-hover-point-text" style={hoverTextPointStyle}>
          {hoverTextPoint}
        </div>
      </div>
      <div className="minimap-hover-text" style={hoverTextMinimapStyle}>
        {hoverTextMinimap}
      </div>
    </div>
  );
};

export default Minimap;
