import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "@components/navbar/Navbar";
import MainContent from "@components/mainContent/MainContent";
import Geology from "@components/pages/geology/Geology";
import NotFound from "@components/pages/notFound/NotFound";
import CountdownPage from "@components/pages/countdown/CountdownPage";
import { HelmetProvider } from "react-helmet-async";
import { logPageView } from "./utils/analytics";
import "./App.css";
import { LangProvider } from "@context/LangContext";
import { MusicProvider } from "@context/MusicContext";
import { BubbleProvider } from "@context/BubbleContext";
import { PriorityProvider } from "./context/PriorityContext";

const App = () => {
  const isSiteBlocked = process.env.REACT_APP_SITE_BLOCKED === "true";

  return (
    <PriorityProvider>
      <LangProvider>
        <MusicProvider>
          <BubbleProvider>
            <HelmetProvider>
              <Router>
                {!isSiteBlocked && <Navbar />}
                <AppRoutes isSiteBlocked={isSiteBlocked} />
              </Router>
            </HelmetProvider>
          </BubbleProvider>
        </MusicProvider>
      </LangProvider>
    </PriorityProvider>
  );
};

const AppRoutes = ({ isSiteBlocked }) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      logPageView();
    }, 1000);
  }, [location]);

  return (
    <Routes>
      {isSiteBlocked ? (
        <Route path="*" element={<CountdownPage />} />
      ) : (
        <>
          <Route path="/" element={<MainContent />} />
          <Route path="/geology" element={<Geology />} />
          <Route path="*" element={<NotFound />} />
          {/* Ajoutez d'autres routes ici */}
        </>
      )}
    </Routes>
  );
};

export default App;
