import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import LazyImage from "@components/LazyImage";

const HighlightMessage = ({
  imageSrc,
  imageSrcWebp,
  imageAlt,
  highlightText,
  position,
  textPosition,
}) => {
  const highlightRef = useRef(null);

  useEffect(() => {
    const highlightElement = highlightRef.current;

    // Animation d'apparition
    gsap.to(highlightElement, {
      opacity: 1,
      y: 0,
      duration: 0.5,
      ease: "power2.out",
      onStart: () => {
        highlightElement.classList.add("visible");
      },
    });

    return () => {
      gsap.to(highlightElement, {
        opacity: 0,
        y: 20,
        duration: 0.5,
        ease: "power2.in",
        onComplete: () => {
          highlightElement.classList.remove("visible");
        },
      });
    };
  }, []);

  return (
    <div
      className={`highlight-message ${textPosition}`}
      style={position}
      ref={highlightRef}
    >
      {textPosition === "left" && (
        <div className="highlight-text">{highlightText}</div>
      )}
      <LazyImage
        className="highlight-image"
        srcPng={imageSrc}
        srcWebp={imageSrcWebp}
        alt={imageAlt}
        priorityLevel={3}
      />
      {textPosition === "right" && (
        <div className="highlight-text">{highlightText}</div>
      )}
    </div>
  );
};

export default HighlightMessage;
