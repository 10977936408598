import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import "./Geology.css";
import backgroundImage from "@assets/geologyAssets/geology_background.png";
import OlivineAnimation from "./OlivineAnimation";
import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";

const Geology = () => {
  const { lang } = useContext(LangContext);

  const pageStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className="geology-page" style={pageStyle}>
      <Helmet>
        <title>The Scrum Nomad - Geology</title>
        <meta
          name="description"
          content="Explore the geological wonders with The Scrum Nomad's geology section, a personal hobby shared for those curious enough to find it."
        />
        <link rel="canonical" href="https://TheScrumNomad.com/geology" />
        <meta property="og:title" content="The Scrum Nomad - Geology" />
        <meta
          property="og:description"
          content="Explore the geological wonders with The Scrum Nomad's geology section, a personal hobby shared for those curious enough to find it."
        />
        <meta property="og:image" content="%PUBLIC_URL%/og-geology.jpg" />
        <meta property="og:url" content="https://TheScrumNomad.com/geology" />
        <meta name="twitter:title" content="The Scrum Nomad - Geology" />
        <meta
          name="twitter:description"
          content="Explore the geological wonders with The Scrum Nomad's geology section, a personal hobby shared for those curious enough to find it."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/twitter-geology.jpg" />
      </Helmet>
      <div className="geology-title">{texts[lang].geology.title}</div>
      <div className="geology-subtitle">{texts[lang].geology.subtitle}</div>
      <OlivineAnimation />
    </div>
  );
};

export default Geology;
