import React, { useCallback, useContext, useEffect, useState } from "react";
import { gsap } from "gsap";
import { MusicContext } from "@context/MusicContext";
import { logButtonClick } from "@utils/analytics";
import "@components/completeSection/completeSection.css";
import "./LoadingPage.css";
import InfoIcon from "./infoIconComponent/InfoIcon";

import {
  imagesStarrySky,
  theLetters,
  scrumLetters,
  nomadLetters,
  imagesStarrySkyWebp,
} from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";
import LogoAnimation from "../../navbar/logoComponent/LogoAnimation";
import { usePriority } from "@context/PriorityContext";

const LoadingPage = ({ setShowLoadingScreen }) => {
  const { togglePlayMusic } = useContext(MusicContext);
  const { priority1Loaded, priority2Loaded } = usePriority();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    if (priority1Loaded && priority2Loaded) {
      setIsButtonEnabled(true);
    }
  }, [priority1Loaded, priority2Loaded]);

  const handleButtonClick = useCallback(() => {
    if (!isButtonEnabled) return;
    logButtonClick("Button", "Clicked Launch Button", "Launch Button");
    const timeline = gsap.timeline();
    timeline.to(".loadingScreen", {
      autoAlpha: 0,
      duration: 1,
      onComplete: () => {
        setShowLoadingScreen(false);
        togglePlayMusic();
      },
    });
    timeline.fromTo(
      ".planetsWrapper",
      { y: -500, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 4, ease: "power1.out" },
      "<"
    );
    timeline.fromTo(
      ".bigPlanetWrapper",
      { y: 500, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 4, ease: "power1.out" },
      "<"
    );
    timeline.fromTo(
      ".atmosphBigPlanetWrapper",
      { y: 500, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 4, ease: "power1.out" },
      "<"
    );
    [...theLetters, ...scrumLetters, ...nomadLetters].forEach((className) => {
      timeline.fromTo(
        `.${className}Container`,
        { y: -500, autoAlpha: 0 },
        {
          y: 0,
          autoAlpha: 1,
          duration: Math.random() * 3 + 2,
          ease: "power1.out",
        },
        "<"
      );
    });
    timeline.fromTo(
      ".asteroidesWrapper",
      { y: 500, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 4, ease: "power1.out" },
      "<"
    );
    [
      "fog1Container",
      "fog2Container",
      "fog3Container",
      "fog4Container",
      "fog5Container",
      "fog6Container",
    ].forEach((className) => {
      timeline.fromTo(
        `.${className}`,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 1.5,
          ease: "power1.inOut",
        },
        "+=0"
      );
    });
  }, [isButtonEnabled, togglePlayMusic, setShowLoadingScreen]);

  return (
    <div className="loadingScreen">
      <LazyImage
        srcPng={imagesStarrySky.loadingScreen}
        srcWebp={imagesStarrySkyWebp.loadingScreenWebp}
        alt="Loading Screen Background"
        className="loadingScreenBackground"
        priorityLevel={1}
      />
      <div className="loadingContent">
        <div className="logoAnimationContainer">
          <LogoAnimation />
        </div>
        <div className="loadingTitle">
          <h1>
            The Scrum Nomad<span className="trademark">&trade;</span>
            <span className="copyright">&copy;</span>
          </h1>
          <h2>by Pierre Filliau</h2>
        </div>
        <button
          className="launchButton"
          onClick={handleButtonClick}
          disabled={!isButtonEnabled}
        >
          <LazyImage
            srcPng={imagesStarrySky.buttonLoadingScreen}
            srcWebp={imagesStarrySkyWebp.buttonLoadingScreenWebp}
            alt="Clic"
            className="launchButtonImg"
            priorityLevel={1}
          />
          {!isButtonEnabled && (
            <div className="spinner">
              <div className="dot1"></div>
              <div className="dot2"></div>
            </div>
          )}
        </button>
        <InfoIcon />
      </div>
    </div>
  );
};

export default LoadingPage;
