import React, { useRef } from "react";
import { gsap } from "gsap";
import ray1 from "@assets/navbarAssets/logoAssets/ray1.png";
import ray2 from "@assets/navbarAssets/logoAssets/ray2.png";
import ray3 from "@assets/navbarAssets/logoAssets/ray3.png";
import ray4 from "@assets/navbarAssets/logoAssets/ray4.png";
import ray5 from "@assets/navbarAssets/logoAssets/ray5.png";
import ray6 from "@assets/navbarAssets/logoAssets/ray6.png";
import ray7 from "@assets/navbarAssets/logoAssets/ray7.png";
import ray8 from "@assets/navbarAssets/logoAssets/ray8.png";
import ray9 from "@assets/navbarAssets/logoAssets/ray9.png";
import ray10 from "@assets/navbarAssets/logoAssets/ray10.png";
import ray11 from "@assets/navbarAssets/logoAssets/ray11.png";
import ray12 from "@assets/navbarAssets/logoAssets/ray12.png";
import ray13 from "@assets/navbarAssets/logoAssets/ray13.png";
import ray14 from "@assets/navbarAssets/logoAssets/ray14.png";
import ray15 from "@assets/navbarAssets/logoAssets/ray15.png";
import logo from "@assets/navbarAssets/logoAssets/logo.png";

import ray1Webp from "@assets/navbarAssets/logoAssets/ray1.webp";
import ray2Webp from "@assets/navbarAssets/logoAssets/ray2.webp";
import ray3Webp from "@assets/navbarAssets/logoAssets/ray3.webp";
import ray4Webp from "@assets/navbarAssets/logoAssets/ray4.webp";
import ray5Webp from "@assets/navbarAssets/logoAssets/ray5.webp";
import ray6Webp from "@assets/navbarAssets/logoAssets/ray6.webp";
import ray7Webp from "@assets/navbarAssets/logoAssets/ray7.webp";
import ray8Webp from "@assets/navbarAssets/logoAssets/ray8.webp";
import ray9Webp from "@assets/navbarAssets/logoAssets/ray9.webp";
import ray10Webp from "@assets/navbarAssets/logoAssets/ray10.webp";
import ray11Webp from "@assets/navbarAssets/logoAssets/ray11.webp";
import ray12Webp from "@assets/navbarAssets/logoAssets/ray12.webp";
import ray13Webp from "@assets/navbarAssets/logoAssets/ray13.webp";
import ray14Webp from "@assets/navbarAssets/logoAssets/ray14.webp";
import ray15Webp from "@assets/navbarAssets/logoAssets/ray15.webp";
import logoWebp from "@assets/navbarAssets/logoAssets/logo.webp";
import "./LogoAnimation.css";
import LazyImage from "@components/LazyImage";
import { logButtonClick } from "@utils/analytics";

const LogoAnimation = () => {
  const logoRef = useRef(null);

  const handleClick = () => {
    logButtonClick("LogoAnimation", "Click", "Logo");

    const rays = logoRef.current.querySelectorAll(".ray");

    gsap.to(rays, {
      duration: 0.5,
      scale: 2.5,
      opacity: 1,
      stagger: 0.1,
      ease: "power1.out",
      onComplete: () => {
        gsap.to(rays, {
          duration: 0.5,
          scale: 1,
          opacity: 0,
          stagger: 0.1,
          ease: "power1.inOut",
        });
      },
    });
  };

  return (
    <div className="logo-container" ref={logoRef} onClick={handleClick}>
      <LazyImage
        srcPng={logo}
        srcWebp={logoWebp}
        alt="Logo"
        className="logo"
        priorityLevel={1}
      />
      <div className="halo"></div>
      <picture>
        <source srcSet={ray1Webp} type="image/webp" />
        <img src={ray1} alt="ray1" className="ray ray1" />
      </picture>
      <picture>
        <source srcSet={ray2Webp} type="image/webp" />
        <img src={ray2} alt="ray2" className="ray ray2" />
      </picture>
      <picture>
        <source srcSet={ray3Webp} type="image/webp" />
        <img src={ray3} alt="ray3" className="ray ray3" />
      </picture>
      <picture>
        <source srcSet={ray4Webp} type="image/webp" />
        <img src={ray4} alt="ray4" className="ray ray4" />
      </picture>
      <picture>
        <source srcSet={ray5Webp} type="image/webp" />
        <img src={ray5} alt="ray5" className="ray ray5" />
      </picture>
      <picture>
        <source srcSet={ray6Webp} type="image/webp" />
        <img src={ray6} alt="ray6" className="ray ray6" />
      </picture>
      <picture>
        <source srcSet={ray7Webp} type="image/webp" />
        <img src={ray7} alt="ray7" className="ray ray7" />
      </picture>
      <picture>
        <source srcSet={ray8Webp} type="image/webp" />
        <img src={ray8} alt="ray8" className="ray ray8" />
      </picture>
      <picture>
        <source srcSet={ray9Webp} type="image/webp" />
        <img src={ray9} alt="ray9" className="ray ray9" />
      </picture>
      <picture>
        <source srcSet={ray10Webp} type="image/webp" />
        <img src={ray10} alt="ray10" className="ray ray10" />
      </picture>
      <picture>
        <source srcSet={ray11Webp} type="image/webp" />
        <img src={ray11} alt="ray11" className="ray ray11" />
      </picture>
      <picture>
        <source srcSet={ray12Webp} type="image/webp" />
        <img src={ray12} alt="ray12" className="ray ray12" />
      </picture>
      <picture>
        <source srcSet={ray13Webp} type="image/webp" />
        <img src={ray13} alt="ray13" className="ray ray13" />
      </picture>
      <picture>
        <source srcSet={ray14Webp} type="image/webp" />
        <img src={ray14} alt="ray14" className="ray ray14" />
      </picture>
      <picture>
        <source srcSet={ray15Webp} type="image/webp" />
        <img src={ray15} alt="ray15" className="ray ray15" />
      </picture>
    </div>
  );
};

export default LogoAnimation;
