import React, { useState, useContext } from "react";
import Character from "./Character";
import SectionView from "./SectionView";
import { charactersData } from "@data/charactersData";
import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";
import { BubbleContext } from "@context/BubbleContext";

const Characters = () => {
  const { lang } = useContext(LangContext);
  const { minimizedBubbles } = useContext(BubbleContext);
  const [activeSection, setActiveSection] = useState(null);
  const [activeColor, setActiveColor] = useState(null);

  const handleShowSection = (section, color) => {
    setActiveSection(section);
    setActiveColor(color);
  };

  const handleCloseSection = () => {
    setActiveSection(null);
    setActiveColor(null);
  };

  const sectionTexts = charactersData.reduce((acc, character) => {
    const section = character.section;
    const characterTexts = texts[lang].characters[character.className];
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section] = acc[section].concat(characterTexts);
    return acc;
  }, {});

  return (
    <>
      {charactersData.map((character) => (
        <Character
          key={character.id}
          id={character.id}
          orientation={character.orientation}
          messages={texts[lang].characters[character.className]}
          className={character.className}
          position={character.position}
          firstSlideColor={character.firstSlideColor}
          section={character.section}
          onShowSection={(section) =>
            handleShowSection(section, character.firstSlideColor)
          }
          isMinimized={!!minimizedBubbles[character.id]}
          onToggleBubble={() => {}}
        />
      ))}
      {activeSection && (
        <SectionView
          sectionText={sectionTexts[activeSection]}
          onClose={handleCloseSection}
          backgroundColor={activeColor}
          sectionName={activeSection}
        />
      )}
    </>
  );
};

export default Characters;
