import React from "react";
import {
  rings,
  imagesStarrySky,
  imagesStarrySkyWebp,
} from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";

const Rings = () => (
  <>
    {rings.map((className, index) => (
      <div key={`${className}-${index}`} className={`${className}Container`}>
        <div className={`${className}Wrapper`}>
          <LazyImage
            className={className}
            srcPng={imagesStarrySky[className]}
            srcWebp={imagesStarrySkyWebp[`${className}Webp`]}
            alt=""
            priorityLevel={3}
          />
        </div>
      </div>
    ))}
  </>
);

export default Rings;
