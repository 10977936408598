import React, { useContext } from "react";
import "./LanguageSwitcher.css";
import { LangContext } from "@context/LangContext";
import langFlag from "@assets/navbarAssets/lang_flag.png";
import langFlagwebp from "@assets/navbarAssets/lang_flag.webp";

import { texts } from "@data/textsData";
import LazyImage from "@components/LazyImage";
import { logButtonClick } from "@utils/analytics";

const LanguageSwitcher = () => {
  const { lang, setLang } = useContext(LangContext);

  const handleLangChange = () => {
    logButtonClick(
      "LanguageSwitcher",
      "Click",
      lang === "en" ? "Switch to French" : "Switch to English"
    );

    setLang(lang === "en" ? "fr" : "en");
  };

  return (
    <div className="hover-container">
      <div className="language-switcher" onClick={handleLangChange}>
        <LazyImage
          srcPng={langFlag}
          srcWebp={langFlagwebp}
          alt="Language Flag"
          className={`flag ${lang}`}
          priorityLevel={1}
        />
      </div>

      <div className="hover-text">
        {lang === "en"
          ? texts.fr.hovers.langToFrench
          : texts.en.hovers.langToEnglish}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
