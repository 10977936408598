import React, { useEffect, useState } from "react";
import gsap from "gsap";
import importedOlivinePositions from "./olivinePositions";
import "./OlivineAnimation.css";

const OlivineAnimation = () => {
  const [olivinePositions, setOlivinePositions] = useState([]);

  const olivineImage = (index) =>
    require(`@assets/geologyAssets/dunite/olivine${index + 1}.png`);

  useEffect(() => {
    setOlivinePositions(importedOlivinePositions);
    return () => {
      gsap.killTweensOf(".olivine");
    };
  }, []);

  const handleMouseEnter = (e) => {
    gsap.to(e.target, { scale: 1.3, duration: 0.3, ease: "power1.out" });
  };

  const handleMouseLeave = (e) => {
    gsap.to(e.target, { scale: 1, duration: 0.5, ease: "power1.inOut" });
  };

  return (
    <div className="mosaicContainer">
      {olivinePositions.map((position, index) => (
        <img
          key={index}
          src={olivineImage(index)}
          className="olivine"
          alt="olivine"
          style={{
            left: `calc(${position.left} - 3vw)`,
            top: `calc(${position.top} - 5vw)`,
            width: position.width,
            height: position.height,
            opacity: 1,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default OlivineAnimation;
