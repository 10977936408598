import React, { useState, useCallback, useEffect, useContext } from "react";
import fullscreenIcons from "@assets/navbarAssets/fullscreen_logo.png";
import fullscreenIconsWebp from "@assets/navbarAssets/fullscreen_logo.webp";

import "./FullscreenToggleButton.css";
import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";
import LazyImage from "@components/LazyImage";
import { logButtonClick } from "@utils/analytics";

const FullscreenToggleButton = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { lang } = useContext(LangContext);

  const handleFullscreenChange = () => {
    setIsFullscreen(
      document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
    );
  };

  const toggleFullscreen = useCallback(() => {
    logButtonClick(
      "FullscreenToggleButton",
      "Click",
      isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"
    );

    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen(); // Firefox
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen(); // IE/Edge
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Firefox
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Chrome, Safari, Opera
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge
      }
    }
  }, [isFullscreen]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  return (
    <div
      className="fullscreen-container hover-container"
      onClick={toggleFullscreen}
    >
      <div className="fullscreen-button">
        <LazyImage
          srcPng={fullscreenIcons}
          srcWebp={fullscreenIconsWebp}
          alt="Fullscreen Icon"
          className={`fullscreen-icon ${isFullscreen ? "exit" : "enter"}`}
          priorityLevel={1}
        />
      </div>
      <div className="hover-text">
        {isFullscreen
          ? texts[lang].hovers.fullScreenOFF
          : texts[lang].hovers.fullScreenON}
      </div>
    </div>
  );
};

export default FullscreenToggleButton;
