// Importation des png
import completeSkyBg from "@assets/starrySkyAssets/completeSkyBg.png";

import theT from "@assets/starrySkyAssets/letters/theT.png";
import theH from "@assets/starrySkyAssets/letters/theH.png";
import theE from "@assets/starrySkyAssets/letters/theE.png";
import scrumS from "@assets/starrySkyAssets/letters/scrumS.png";
import scrumC from "@assets/starrySkyAssets/letters/scrumC.png";
import scrumR from "@assets/starrySkyAssets/letters/scrumR.png";
import scrumU from "@assets/starrySkyAssets/letters/scrumU.png";
import scrumM from "@assets/starrySkyAssets/letters/scrumM.png";
import nomadN from "@assets/starrySkyAssets/letters/nomadN.png";
import nomadO from "@assets/starrySkyAssets/letters/nomadO.png";
import nomadM from "@assets/starrySkyAssets/letters/nomadM.png";
import nomadA from "@assets/starrySkyAssets/letters/nomadA.png";
import nomadD from "@assets/starrySkyAssets/letters/nomadD.png";
import asteroides1 from "@assets/starrySkyAssets/asteroides1.png";
import asteroides2 from "@assets/starrySkyAssets/asteroides2.png";
import asteroides3 from "@assets/starrySkyAssets/asteroides3.png";
import blackhole2 from "@assets/starrySkyAssets/blackhole2.png";
import blackhole3 from "@assets/starrySkyAssets/blackhole3.png";
import planet1 from "@assets/starrySkyAssets/planets/planet1.png";
import planet2 from "@assets/starrySkyAssets/planets/planet2.png";
import planet3 from "@assets/starrySkyAssets/planets/planet3.png";
import planet4 from "@assets/starrySkyAssets/planets/planet4.png";
import planet5 from "@assets/starrySkyAssets/planets/planet5.png";
import planet6 from "@assets/starrySkyAssets/planets/planet6.png";
import planet7 from "@assets/starrySkyAssets/planets/planet7.png";
import planet8 from "@assets/starrySkyAssets/planets/planet8.png";
import planet9 from "@assets/starrySkyAssets/planets/planet9.png";
import planet10 from "@assets/starrySkyAssets/planets/planet10.png";
import planet11 from "@assets/starrySkyAssets/planets/planet11.png";
import rings1 from "@assets/starrySkyAssets/rings1.png";
import rings2 from "@assets/starrySkyAssets/rings2.png";
import rings3 from "@assets/starrySkyAssets/rings3.png";
import rings4 from "@assets/starrySkyAssets/rings4.png";
import bigPlanet from "@assets/starrySkyAssets/planets/bigPlanet.png";
import atmosphBigPlanet from "@assets/starrySkyAssets/planets/atmosphere1.png";
import fog1 from "@assets/starrySkyAssets/fog/fogWCr.png";
import fog2 from "@assets/starrySkyAssets/fog/fogWinter.png";
import fog3 from "@assets/starrySkyAssets/fog/fogWScr.png";
import fog4 from "@assets/starrySkyAssets/fog/fogWalone.png";
import fog5 from "@assets/starrySkyAssets/fog/fogWalone.png";
import fog6 from "@assets/starrySkyAssets/fog/fogWalone.png";
import spaceShipA1 from "@assets/starrySkyAssets/spaceStation/spaceShipA1.png";
import spaceShipA2 from "@assets/starrySkyAssets/spaceStation/spaceShipA2.png";
import spaceShipA3 from "@assets/starrySkyAssets/spaceStation/spaceShipA3.png";
import spaceShipA4 from "@assets/starrySkyAssets/spaceStation/spaceShipA4.png";
import spaceShipA5 from "@assets/starrySkyAssets/spaceStation/spaceShipA4.png";
import spaceShipA6 from "@assets/starrySkyAssets/spaceStation/spaceShipA4.png";
import spaceShipB1 from "@assets/starrySkyAssets/spaceStation/spaceShipB1.png";
import spaceShipB2 from "@assets/starrySkyAssets/spaceStation/spaceShipB2.png";
import spaceShipB3 from "@assets/starrySkyAssets/spaceStation/spaceShipB3.png";
import spaceShipB4 from "@assets/starrySkyAssets/spaceStation/spaceShipB2.png";

import spaceShipC1 from "@assets/starrySkyAssets/spaceStation/spaceShipC1.png";
import spaceShipC2 from "@assets/starrySkyAssets/spaceStation/spaceShipC2.png";
import spaceShipC3 from "@assets/starrySkyAssets/spaceStation/spaceShipC3.png";

import spaceShipD1 from "@assets/starrySkyAssets/spaceStation/spaceShipD1.png";
import spaceShipD2 from "@assets/starrySkyAssets/spaceStation/spaceShipD2.png";
import spaceShipD3 from "@assets/starrySkyAssets/spaceStation/spaceShipD3.png";
import spaceShipD4 from "@assets/starrySkyAssets/spaceStation/spaceShipD4.png";
import spaceShipE1 from "@assets/starrySkyAssets/spaceStation/spaceShipE1.png";
import spaceShipE2 from "@assets/starrySkyAssets/spaceStation/spaceShipE2.png";
import spaceShipE3 from "@assets/starrySkyAssets/spaceStation/spaceShipE3.png";
import spaceShipF1 from "@assets/starrySkyAssets/spaceStation/spaceShipF1.png";
import spaceShipF2 from "@assets/starrySkyAssets/spaceStation/spaceShipF2.png";
import spaceShipF3 from "@assets/starrySkyAssets/spaceStation/spaceShipF3.png";
import spaceShipF4 from "@assets/starrySkyAssets/spaceStation/spaceShipF4.png";

import spaceShipG1 from "@assets/starrySkyAssets/spaceStation/spaceShipG1.png";
import spaceShipG2 from "@assets/starrySkyAssets/spaceStation/spaceShipG2.png";
import spaceShipG3 from "@assets/starrySkyAssets/spaceStation/spaceShipG3.png";
import spaceShipG4 from "@assets/starrySkyAssets/spaceStation/spaceShipG4.png";

import spaceShipH1 from "@assets/starrySkyAssets/spaceStation/spaceShipH1.png";
import spaceShipH2 from "@assets/starrySkyAssets/spaceStation/spaceShipH2.png";
import spaceShipH3 from "@assets/starrySkyAssets/spaceStation/spaceShipH3.png";

import spaceStation1 from "@assets/starrySkyAssets/spaceStation/spaceStation1.png";
import spaceStation2 from "@assets/starrySkyAssets/spaceStation/spaceStation2.png";
import spaceStation3 from "@assets/starrySkyAssets/spaceStation/spaceStation3.png";
import spaceStation4 from "@assets/starrySkyAssets/spaceStation/spaceStation4.png";
import spaceStation5 from "@assets/starrySkyAssets/spaceStation/spaceStation5.png";
import spaceStation6 from "@assets/starrySkyAssets/spaceStation/spaceStation6.png";
import spaceStation8 from "@assets/starrySkyAssets/spaceStation/spaceStation8.png";

import loadingScreen from "@assets/loadingPageAssets/loadingScreen.png";
import buttonLoadingScreen from "@assets/loadingPageAssets/buttonLoadingScreen.png";

// Importation des images webp
// import completeSkyBgWebp from "@assets/starrySkyAssets/completeSkyBg.webp";
import theTWebp from "@assets/starrySkyAssets/letters/theT.webp";
import theHWebp from "@assets/starrySkyAssets/letters/theH.webp";
import theEWebp from "@assets/starrySkyAssets/letters/theE.webp";
import scrumSWebp from "@assets/starrySkyAssets/letters/scrumS.webp";
import scrumCWebp from "@assets/starrySkyAssets/letters/scrumC.webp";
import scrumRWebp from "@assets/starrySkyAssets/letters/scrumR.webp";
import scrumUWebp from "@assets/starrySkyAssets/letters/scrumU.webp";
import scrumMWebp from "@assets/starrySkyAssets/letters/scrumM.webp";
import nomadNWebp from "@assets/starrySkyAssets/letters/nomadN.webp";
import nomadOWebp from "@assets/starrySkyAssets/letters/nomadO.webp";
import nomadMWebp from "@assets/starrySkyAssets/letters/nomadM.webp";
import nomadAWebp from "@assets/starrySkyAssets/letters/nomadA.webp";
import nomadDWebp from "@assets/starrySkyAssets/letters/nomadD.webp";
import asteroides1Webp from "@assets/starrySkyAssets/asteroides1.webp";
import asteroides2Webp from "@assets/starrySkyAssets/asteroides2.webp";
import asteroides3Webp from "@assets/starrySkyAssets/asteroides3.webp";
import blackhole2Webp from "@assets/starrySkyAssets/blackhole2.webp";
import blackhole3Webp from "@assets/starrySkyAssets/blackhole3.webp";
import planet1Webp from "@assets/starrySkyAssets/planets/planet1.webp";
import planet2Webp from "@assets/starrySkyAssets/planets/planet2.webp";
import planet3Webp from "@assets/starrySkyAssets/planets/planet3.webp";
import planet4Webp from "@assets/starrySkyAssets/planets/planet4.webp";
import planet5Webp from "@assets/starrySkyAssets/planets/planet5.webp";
import planet6Webp from "@assets/starrySkyAssets/planets/planet6.webp";
import planet7Webp from "@assets/starrySkyAssets/planets/planet7.webp";
import planet8Webp from "@assets/starrySkyAssets/planets/planet8.webp";
import planet9Webp from "@assets/starrySkyAssets/planets/planet9.webp";
import planet10Webp from "@assets/starrySkyAssets/planets/planet10.webp";
import planet11Webp from "@assets/starrySkyAssets/planets/planet11.webp";
import rings1Webp from "@assets/starrySkyAssets/rings1.webp";
import rings2Webp from "@assets/starrySkyAssets/rings2.webp";
import rings3Webp from "@assets/starrySkyAssets/rings3.webp";
import rings4Webp from "@assets/starrySkyAssets/rings4.webp";
import bigPlanetWebp from "@assets/starrySkyAssets/planets/bigPlanet.webp";
import atmosphBigPlanetWebp from "@assets/starrySkyAssets/planets/atmosphere1.webp";
import fog1Webp from "@assets/starrySkyAssets/fog/fogWCr.webp";
import fog2Webp from "@assets/starrySkyAssets/fog/fogWinter.webp";
import fog3Webp from "@assets/starrySkyAssets/fog/fogWScr.webp";
import fog4Webp from "@assets/starrySkyAssets/fog/fogWalone.webp";
import fog5Webp from "@assets/starrySkyAssets/fog/fogWalone.webp";
import fog6Webp from "@assets/starrySkyAssets/fog/fogWalone.webp";
import spaceShipA1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipA1.webp";
import spaceShipA2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipA2.webp";
import spaceShipA3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipA3.webp";
import spaceShipA4Webp from "@assets/starrySkyAssets/spaceStation/spaceShipA4.webp";
import spaceShipA5Webp from "@assets/starrySkyAssets/spaceStation/spaceShipA4.webp";
import spaceShipA6Webp from "@assets/starrySkyAssets/spaceStation/spaceShipA4.webp";
import spaceShipB1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipB1.webp";
import spaceShipB2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipB2.webp";
import spaceShipB3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipB3.webp";
import spaceShipB4Webp from "@assets/starrySkyAssets/spaceStation/spaceShipB2.webp";
import spaceShipC1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipC1.webp";
import spaceShipC2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipC2.webp";
import spaceShipC3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipC3.webp";
import spaceShipD1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipD1.webp";
import spaceShipD2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipD2.webp";
import spaceShipD3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipD3.webp";
import spaceShipD4Webp from "@assets/starrySkyAssets/spaceStation/spaceShipD4.webp";
import spaceShipE1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipE1.webp";
import spaceShipE2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipE2.webp";
import spaceShipE3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipE3.webp";
import spaceShipF1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipF1.webp";
import spaceShipF2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipF2.webp";
import spaceShipF3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipF3.webp";
import spaceShipF4Webp from "@assets/starrySkyAssets/spaceStation/spaceShipF4.webp";
import spaceShipG1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipG1.webp";
import spaceShipG2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipG2.webp";
import spaceShipG3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipG3.webp";
import spaceShipG4Webp from "@assets/starrySkyAssets/spaceStation/spaceShipG4.webp";
import spaceShipH1Webp from "@assets/starrySkyAssets/spaceStation/spaceShipH1.webp";
import spaceShipH2Webp from "@assets/starrySkyAssets/spaceStation/spaceShipH2.webp";
import spaceShipH3Webp from "@assets/starrySkyAssets/spaceStation/spaceShipH3.webp";
import spaceStation1Webp from "@assets/starrySkyAssets/spaceStation/spaceStation1.webp";
import spaceStation2Webp from "@assets/starrySkyAssets/spaceStation/spaceStation2.webp";
import spaceStation3Webp from "@assets/starrySkyAssets/spaceStation/spaceStation3.webp";
import spaceStation4Webp from "@assets/starrySkyAssets/spaceStation/spaceStation4.webp";
import spaceStation5Webp from "@assets/starrySkyAssets/spaceStation/spaceStation5.webp";
import spaceStation6Webp from "@assets/starrySkyAssets/spaceStation/spaceStation6.webp";
import spaceStation8Webp from "@assets/starrySkyAssets/spaceStation/spaceStation8.webp";
import loadingScreenWebp from "../assets/loadingPageAssets/loadingScreen.webp";

import buttonLoadingScreenWebp from "@assets/loadingPageAssets/buttonLoadingScreen.webp";

// Exportation des images et des données pour les éléments animés dans la section starrySky
export const imagesStarrySky = {
  completeSkyBg,
  loadingScreen,
  buttonLoadingScreen,
  loadingScreenWebp,
  buttonLoadingScreenWebp,
  theT,
  theH,
  theE,
  scrumS,
  scrumC,
  scrumR,
  scrumU,
  scrumM,
  nomadN,
  nomadO,
  nomadM,
  nomadA,
  nomadD,
  asteroides1,
  asteroides2,
  asteroides3,
  blackhole2,
  blackhole3,
  planet1,
  planet2,
  planet3,
  planet4,
  planet5,
  planet6,
  planet7,
  planet8,
  planet9,
  planet10,
  planet11,
  rings1,
  rings2,
  rings3,
  rings4,
  bigPlanet,
  atmosphBigPlanet,
  fog1,
  fog2,
  fog3,
  fog4,
  fog5,
  fog6,
  spaceShipA1,
  spaceShipA2,
  spaceShipA3,
  spaceShipA4,
  spaceShipA5,
  spaceShipA6,
  spaceShipB1,
  spaceShipB2,
  spaceShipB3,
  spaceShipB4,
  spaceShipC1,
  spaceShipC2,
  spaceShipC3,
  spaceShipD1,
  spaceShipD2,
  spaceShipD3,
  spaceShipD4,
  spaceShipE1,
  spaceShipE2,
  spaceShipE3,
  spaceShipF1,
  spaceShipF2,
  spaceShipF3,
  spaceShipF4,
  spaceShipG1,
  spaceShipG2,
  spaceShipG3,
  spaceShipG4,
  spaceShipH1,
  spaceShipH2,
  spaceShipH3,
  spaceStation1,
  spaceStation2,
  spaceStation3,
  spaceStation4,
  spaceStation5,
  spaceStation6,
  spaceStation8,
};

export const imagesStarrySkyWebp = {
  // completeSkyBgWebp,
  theTWebp,
  theHWebp,
  theEWebp,
  scrumSWebp,
  scrumCWebp,
  scrumRWebp,
  scrumUWebp,
  scrumMWebp,
  nomadNWebp,
  nomadOWebp,
  nomadMWebp,
  nomadAWebp,
  nomadDWebp,
  asteroides1Webp,
  asteroides2Webp,
  asteroides3Webp,
  blackhole2Webp,
  blackhole3Webp,
  planet1Webp,
  planet2Webp,
  planet3Webp,
  planet4Webp,
  planet5Webp,
  planet6Webp,
  planet7Webp,
  planet8Webp,
  planet9Webp,
  planet10Webp,
  planet11Webp,
  rings1Webp,
  rings2Webp,
  rings3Webp,
  rings4Webp,
  bigPlanetWebp,
  atmosphBigPlanetWebp,
  fog1Webp,
  fog2Webp,
  fog3Webp,
  fog4Webp,
  fog5Webp,
  fog6Webp,
  spaceShipA1Webp,
  spaceShipA2Webp,
  spaceShipA3Webp,
  spaceShipA4Webp,
  spaceShipA5Webp,
  spaceShipA6Webp,
  spaceShipB1Webp,
  spaceShipB2Webp,
  spaceShipB3Webp,
  spaceShipB4Webp,
  spaceShipC1Webp,
  spaceShipC2Webp,
  spaceShipC3Webp,
  spaceShipD1Webp,
  spaceShipD2Webp,
  spaceShipD3Webp,
  spaceShipD4Webp,
  spaceShipE1Webp,
  spaceShipE2Webp,
  spaceShipE3Webp,
  spaceShipF1Webp,
  spaceShipF2Webp,
  spaceShipF3Webp,
  spaceShipF4Webp,
  spaceShipG1Webp,
  spaceShipG2Webp,
  spaceShipG3Webp,
  spaceShipG4Webp,
  spaceShipH1Webp,
  spaceShipH2Webp,
  spaceShipH3Webp,
  spaceStation1Webp,
  spaceStation2Webp,
  spaceStation3Webp,
  spaceStation4Webp,
  spaceStation5Webp,
  spaceStation6Webp,
  spaceStation8Webp,
  loadingScreenWebp,
  buttonLoadingScreenWebp,
};

// Noms des classes pour les lettres et autres éléments
export const theLetters = ["T", "H", "E"].map((letter) => `the${letter}`);
export const scrumLetters = ["S", "C", "R", "U", "M"].map(
  (letter) => `scrum${letter}`
);
export const nomadLetters = ["N", "O", "M", "A", "D"].map(
  (letter) => `nomad${letter}`
);

export const asteroides = ["asteroides1", "asteroides2", "asteroides3"];
export const asteroidesClones = [
  "asteroides1-clone",
  "asteroides2-clone",
  "asteroides3-clone",
];
export const blackholes = ["blackhole2", "blackhole3"];
export const planets = [
  "planet1",
  "planet2",
  "planet3",
  "planet4",
  "planet5",
  "planet6",
  "planet7",
  "planet8",
  "planet9",
  "planet10",
  "planet11",
];
export const rings = ["rings1", "rings2", "rings3", "rings4"];

export const fogs = ["fog1", "fog2", "fog3", "fog4", "fog5", "fog6"];

export const spaceShipsA = [
  "spaceShipA1",
  "spaceShipA2",
  "spaceShipA3",
  "spaceShipA4",
  "spaceShipA5",
  "spaceShipA6",
];
export const spaceShipsB = [
  "spaceShipB1",
  "spaceShipB2",
  "spaceShipB3",
  "spaceShipB4",
];
export const spaceShipsC = ["spaceShipC1", "spaceShipC2", "spaceShipC3"];
export const spaceShipsD = [
  "spaceShipD1",
  "spaceShipD2",
  "spaceShipD3",
  "spaceShipD4",
];
export const spaceShipsE = ["spaceShipE1", "spaceShipE2", "spaceShipE3"];
export const spaceShipsF = [
  "spaceShipF1",
  "spaceShipF2",
  "spaceShipF3",
  "spaceShipF4",
];
export const spaceShipsG = [
  "spaceShipG1",
  "spaceShipG2",
  "spaceShipG3",
  "spaceShipG4",
];

export const spaceShipsH = ["spaceShipH1", "spaceShipH2", "spaceShipH3"];

export const spaceshipGroups = [
  spaceShipsA,
  spaceShipsB,
  spaceShipsC,
  spaceShipsD,
  spaceShipsE,
  spaceShipsF,
  spaceShipsG,
  spaceShipsH,
];

export const spaceStations = [
  "spaceStation1",
  "spaceStation2",
  "spaceStation3",
  "spaceStation4",
  "spaceStation5",
  "spaceStation6",
  "spaceStation8",
];
