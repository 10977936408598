import React, { useContext, useEffect, useRef } from "react";
import "./MusicPlayer.css";
import { MusicContext } from "@context/MusicContext";
import backgroundMusic from "@assets/navbarAssets/musicAssets/RazBurgStarsSurfing.mp3";
import musicIcon from "@assets/navbarAssets/musicAssets/music_icons.png";
import musicIconWebp from "@assets/navbarAssets/musicAssets/music_icons.webp";
import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";
import LazyImage from "@components/LazyImage";
import { logButtonClick } from "@utils/analytics";

const MusicPlayer = () => {
  const { playMusic, togglePlayMusic } = useContext(MusicContext);
  const audioRef = useRef(new Audio(backgroundMusic));
  const { lang } = useContext(LangContext);

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = true;
    audio.volume = 0.2; // volume initial à 20%

    if (playMusic) {
      audio.play();
    } else {
      audio.pause();
    }

    // Nettoyage de l'audio lors du démontage du composant
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [playMusic]);

  const handleMusicToggle = () => {
    logButtonClick(
      "MusicPlayer",
      "Click",
      playMusic ? "Stop Music" : "Start Music"
    );
    togglePlayMusic();
  };

  return (
    <div className="hover-container" onClick={handleMusicToggle}>
      <div className="music-player">
        <LazyImage
          srcPng={musicIcon}
          srcWebp={musicIconWebp}
          alt="Music Icon"
          className={`music-icon ${playMusic ? "play" : "pause"}`}
          priorityLevel={1}
        />
      </div>
      <div className="hover-text">
        {playMusic
          ? texts[lang].hovers.musicStop
          : texts[lang].hovers.musicStart}
        <br />
        <a
          href="https://artlist.io/royalty-free-music/song/stars-surfing/51280"
          target="_blank"
          rel="noopener noreferrer"
        >
          Star Surfing,
        </a>
        <br />
        <strong>Raz Burg</strong>
      </div>
    </div>
  );
};

export default MusicPlayer;
