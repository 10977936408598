export const charactersData = [
  //Intro
  {
    id: 1,
    orientation: "right",
    className: "character1",
    section: "introduction",
    position: { top: "56vw", left: "5vw" },
    firstSlideColor: "#0060FF70",
  },
  {
    id: 2,
    orientation: "left",
    className: "character2",
    section: "introduction",
    position: { top: "74vw", left: "82vw" },
    firstSlideColor: "#0060FF70",
  },
  {
    id: 3,
    orientation: "right",
    className: "character3",
    section: "introduction",
    position: { top: "103vw", left: "5vw" },
    firstSlideColor: "#0060FF70",
  },
  {
    id: 4,
    orientation: "left",
    className: "character4",
    section: "introduction",
    position: { top: "114vw", left: "82vw" },
    firstSlideColor: "#0060FF70",
  },
  //Agility
  {
    id: 5,
    orientation: "right",
    className: "character5",
    section: "agility",
    position: { top: "160vw", left: "11vw" },
    firstSlideColor: "rgba(29, 192, 228, 0.7)",
  },
  {
    id: 6,
    orientation: "left",
    className: "character6",
    section: "agility",
    position: { top: "182vw", left: "79vw" },
    firstSlideColor: "rgba(29, 192, 228, 0.7)",
  },
  {
    id: 7,
    orientation: "right",
    className: "character7",
    section: "agility",
    position: { top: "209vw", left: "3vw" },
    firstSlideColor: "rgba(29, 192, 228, 0.7)",
  },
  {
    id: 8,
    orientation: "right",
    className: "character8",
    section: "agility",
    position: { top: "241vw", left: "4vw" },
    firstSlideColor: "rgba(29, 192, 228, 0.7)",
  },
  {
    id: 9,
    orientation: "left",
    className: "character9",
    section: "agility",
    position: { top: "272vw", left: "80vw" },
    firstSlideColor: "rgba(29, 192, 228, 0.7)",
  },
  // technical
  {
    id: 10,
    orientation: "left",
    className: "character10",
    section: "technical",
    position: { top: "298vw", left: "77vw" },
    firstSlideColor: "#FF9C00B8",
  },
  {
    id: 11,
    orientation: "right",
    className: "character11",
    section: "technical",
    position: { top: "325vw", left: "5vw" },
    firstSlideColor: "#FF9C00B8",
  },
  {
    id: 12,
    orientation: "left",
    className: "character12",
    section: "technical",
    position: { top: "350vw", left: "81vw" },
    firstSlideColor: "#FF9C00B8",
  },
  //Role
  {
    id: 13,
    orientation: "right",
    className: "character13",
    section: "role",
    position: { top: "381vw", left: "9vw" },
    firstSlideColor: "#009d0fb3",
  },
  {
    id: 14,
    orientation: "right",
    className: "character14",
    section: "role",
    position: { top: "407vw", left: "3vw" },
    firstSlideColor: "#009d0fb3",
  },

  //Exp
  {
    id: 15,
    orientation: "left",
    className: "character15",
    section: "experience",
    position: { top: "436vw", left: "78vw" },
    firstSlideColor: "#de3013bf",
  },
  {
    id: 16,
    orientation: "right",
    className: "character16",
    section: "experience",
    position: { top: "458vw", left: "5vw" },
    firstSlideColor: "#de3013bf",
  },
  {
    id: 17,
    orientation: "left",
    className: "character17",
    section: "experience",
    position: { top: "473vw", left: "79vw" },
    firstSlideColor: "#de3013bf",
  },
  {
    id: 18,
    orientation: "right",
    className: "character18",
    section: "experience",
    position: { top: "495vw", left: "4vw" },
    firstSlideColor: "#de3013bf",
  },
  //Parcours
  {
    id: 19,
    orientation: "right",
    className: "character19",
    section: "scholar",
    position: { top: "543vw", left: "3vw" },
    firstSlideColor: "#FFD700A8",
  },
  {
    id: 20,
    orientation: "left",
    className: "character20",
    section: "scholar",
    position: { top: "564vw", left: "81vw" },
    firstSlideColor: "#FFD700A8",
  },
  //contact
  {
    id: 21,
    orientation: "right",
    className: "character21",
    section: "contact",
    position: { top: "600vw", left: "6vw" },
    firstSlideColor: "rgba(255, 255, 255, 0.4)",
  },
  {
    id: 22,
    orientation: "left",
    className: "character22",
    section: "contact",
    position: { top: "616vw", left: "85vw" },
    firstSlideColor: "rgba(255, 255, 255, 0.4)",
  },
];
