import React from "react";
import bigPlanetImage from "@assets/starrySkyAssets/planets/bigPlanet.png";
import atmosphBigPlanet from "@assets/starrySkyAssets/planets/atmosphere1.png";
import bigPlanetImageWebp from "@assets/starrySkyAssets/planets/bigPlanet.webp";
import atmosphBigPlanetWebp from "@assets/starrySkyAssets/planets/atmosphere1.webp";
import LazyImage from "@components/LazyImage";

const BigPlanet = () => (
  <div className="floatingWrapper">
    <div className="bigPlanetContainer">
      <div className="bigPlanetWrapper">
        <LazyImage
          className="bigPlanet"
          srcPng={bigPlanetImage}
          srcWebp={bigPlanetImageWebp}
          alt="Big Planet"
          priorityLevel={2}
        />
      </div>
    </div>

    <div className="atmosphBigPlanetContainer">
      <div className="atmosphBigPlanetWrapper">
        <LazyImage
          className="atmosphBigPlanet"
          srcPng={atmosphBigPlanet}
          srcWebp={atmosphBigPlanetWebp}
          alt="atmosphBigPlanet"
          priorityLevel={2}
        />
      </div>
    </div>
  </div>
);

export default BigPlanet;
