import React from "react";
import {
  groundParts,
  imagesBlueSky,
  imagesBlueSkyWebp,
} from "@data/blueSkyData";

const GroundParts = () => (
  <>
    {groundParts.map((className, index) => (
      <div key={`${className}-${index}`} className={`${className}Container`}>
        <div className={`${className}Wrapper`}>
          <picture>
            <source
              srcSet={imagesBlueSkyWebp[`${className}Webp`]}
              type="image/webp"
            />
            <img src={imagesBlueSky[className]} alt="" className={className} />
          </picture>
        </div>
      </div>
    ))}
  </>
);

export default GroundParts;
