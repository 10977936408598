import React, { useState, useContext, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpRightAndDownLeftFromCenter,
  faXmark,
  faChevronRight,
  faChevronLeft,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
import CharacterAnimated from "./CharacterAnimated";
import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";
import { BubbleContext } from "@context/BubbleContext";
import "./Characters.css";
import "./Character.css";
import LazyImage from "@components/LazyImage";
import HighlightMessage from "./HighlightMessage";

const Character = ({
  id,
  orientation,
  messages,
  position,
  firstSlideColor,
  section,
  onShowSection,
  className,
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const { lang } = useContext(LangContext);
  const { minimizedBubbles, toggleBubble } = useContext(BubbleContext);

  const viewSectionButtonRef = useRef(null);
  const closeBubbleButtonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      gsap.set(viewSectionButtonRef.current, { scale: 1 });
      gsap.set(closeBubbleButtonRef.current, { scale: 1 });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!messages || messages.length === 0) {
    return null;
  }

  // Fonction pour découper les paragraphGroup et list en éléments individuels
  const splitParagraphGroupsAndLists = (messages) => {
    return messages.flatMap((message) => {
      if (message.type === "paragraphGroup") {
        return message.content.map((content) => ({
          type: "paragraph",
          content: content.text,
          highlighted: content.highlighted,
        }));
      }
      if (message.type === "list") {
        return message.content.map((item) => ({
          type: "listItem",
          content: item,
          highlighted: message.highlighted,
        }));
      }
      return message;
    });
  };

  const splitMessages = splitParagraphGroupsAndLists(messages);

  // Ajouter la dernière slide
  splitMessages.push({ type: "end" });

  // Calcul du nombre de slide
  const calculateTotalSlides = (messages) => {
    return messages.reduce((total, message) => {
      if (message.type === "highlight") {
        return total + message.highlights.length;
      }
      return total + 1;
    }, 0);
  };

  const totalSlides = calculateTotalSlides(splitMessages);

  const calculateCurrentSlideIndex = (
    messages,
    currentIndex,
    highlightIndex
  ) => {
    let slideIndex = 0;

    for (let i = 0; i <= currentIndex; i++) {
      const message = messages[i];
      if (!message) {
        continue;
      }

      if (message.type === "highlight") {
        if (i === currentIndex) {
          slideIndex += highlightIndex + 1;
        } else {
          slideIndex += message.highlights.length;
        }
      } else {
        slideIndex += 1;
      }
    }

    return slideIndex;
  };

  const currentSlideIndex = calculateCurrentSlideIndex(
    splitMessages,
    currentMessageIndex,
    highlightIndex
  );

  const handleNextMessage = (e) => {
    e.stopPropagation();
    if (minimizedBubbles[id]) {
      return;
    }
    if (
      splitMessages[currentMessageIndex].type === "highlight" &&
      highlightIndex < splitMessages[currentMessageIndex].highlights.length - 1
    ) {
      setHighlightIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentMessageIndex(
        (prevIndex) => (prevIndex + 1) % splitMessages.length
      );
      setHighlightIndex(0);
    }
  };

  const handlePrevMessage = () => {
    setCurrentMessageIndex((prevIndex) =>
      prevIndex === 0 ? splitMessages.length - 1 : prevIndex - 1
    );
    setHighlightIndex(0);
  };

  const handleResetMessage = () => {
    setCurrentMessageIndex(0);
    setHighlightIndex(0);
  };

  const handleButtonClick = (buttonRef) => {
    gsap.fromTo(
      buttonRef.current,
      { scale: 1 },
      { scale: 0.9, duration: 0.1, yoyo: true, repeat: 1 }
    );
  };

  const getBubbleStyle = () => {
    if (currentMessageIndex === 0) {
      return { background: firstSlideColor };
    }
    return {};
  };

  const bubbleClass =
    orientation === "left" ? "speech-bubble-left" : "speech-bubble-right";

  const minimizedBubbleStyle =
    orientation === "left"
      ? { top: "1vh", right: "-1vw", background: firstSlideColor }
      : { top: "1vh", left: "6vw", background: firstSlideColor };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const highlightText = (text, highlights) => {
    if (!highlights) return text;

    const escapedHighlights = highlights.map((h) => escapeRegExp(h.text));
    const parts = text.split(
      new RegExp(`(${escapedHighlights.join("|")})`, "gi")
    );

    return parts.map((part, index) => {
      const highlight = highlights.find(
        (h) => h.text.toLowerCase() === part.toLowerCase()
      );
      if (highlight) {
        return (
          <span key={index} className={highlight.style}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const renderMessageContent = (message) => {
    if (!message) return null;

    if (message?.type === "highlight") {
      return (
        <div className="bubble-paragraph bubble-text">
          {highlightText(
            message.highlights[highlightIndex].content,
            message.highlights[highlightIndex].highlighted
          )}
        </div>
      );
    }
    if (message?.type === "end") {
      return (
        <div className="end-slide">
          <span className="end-text">
            {texts[lang].otherTexts.lastSlideMessage}
          </span>
          <FontAwesomeIcon icon={faRedo} className="blue-icon end-icon" />
        </div>
      );
    }

    switch (message?.type) {
      case "title":
        return (
          <div className="bubble-title bubble-text">
            {highlightText(message.content, message.highlighted)}
          </div>
        );
      case "subtitle":
        return (
          <div className="bubble-subtitle bubble-text">
            {highlightText(message.content, message.highlighted)}
          </div>
        );
      case "paragraph":
        return (
          <div className="bubble-paragraph bubble-text">
            {highlightText(message.content, message.highlighted)}
          </div>
        );
      case "subsubtitle":
        return (
          <div className="bubble-subsubtitle">
            {highlightText(message.content, message.highlighted)}
          </div>
        );
      case "list":
        return (
          <ul className="bubble-list bubble-text">
            {message.content.map((item, idx) => (
              <li key={idx}>{highlightText(item, message.highlighted)}</li>
            ))}
          </ul>
        );
      case "listItem":
        return (
          <li className="bubble-list-item bubble-text">
            {highlightText(message.content, message.highlighted)}
          </li>
        );
      case "image":
        return (
          <LazyImage
            className="bubble-image"
            src={message.src}
            alt={message.alt}
            priorityLevel={3}
          />
        );
      case "socialLink":
        return (
          <div className="social-link">
            <a
              href={message.content.href}
              target="_blank"
              rel="noopener noreferrer"
              className="social-link-anchor"
            >
              <LazyImage
                className="social-link-logo"
                srcPng={message.content.srcPng}
                srcWebp={message.content.srcWebp}
                alt={message.content.alt}
                priorityLevel={3}
              />
              <span className="social-link-text">{message.content.text}</span>
            </a>
          </div>
        );
      default:
        return (
          <div className="bubble-paragraph bubble-text">
            {highlightText(message?.content, message.highlighted)}
          </div>
        );
    }
  };

  return (
    <div
      className={`character ${className}`}
      style={position}
      onClick={handleNextMessage}
    >
      <div className={`${className}Container`}>
        <div className="floatingWrapper">
          <div className={`${className}Wrapper`}>
            <CharacterAnimated orientation={orientation} />
            <div
              className={`speech-bubble ${bubbleClass}`}
              style={{
                ...getBubbleStyle(),
                display: minimizedBubbles[id] ? "none" : "block",
                background: firstSlideColor,
              }}
            >
              <div className="speech-bubble-header">
                <button
                  className="control-button view-section-button hover-container view-hover-container"
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowSection(section);
                    toggleBubble(id);
                    handleButtonClick(viewSectionButtonRef);
                  }}
                  ref={viewSectionButtonRef}
                >
                  <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                  <div className="hover-text view-hover-text">
                    {texts[lang]?.hovers?.viewSection}
                  </div>
                </button>
                <button
                  className="control-button close-bubble-button hover-container close-hover-container"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleResetMessage();
                    toggleBubble(id);
                    handleButtonClick(closeBubbleButtonRef);
                  }}
                  ref={closeBubbleButtonRef}
                >
                  <FontAwesomeIcon icon={faXmark} />
                  <div className="hover-text close-hover-text">
                    {texts[lang]?.hovers?.closeBubble}
                  </div>
                </button>
              </div>
              <div
                className="speech-bubble-content"
                onClick={handleNextMessage}
              >
                {renderMessageContent(splitMessages[currentMessageIndex])}
                <div className="footer-container">
                  <div className="indicator-container">
                    <button
                      className="control-button hover-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePrevMessage();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="green-icon"
                      />
                      <div className="hover-text">
                        {texts[lang].hovers.prevSlide}
                      </div>
                    </button>
                    <span className="slide-counter">
                      {currentSlideIndex} / {totalSlides}
                    </span>
                    <button
                      className="control-button hover-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNextMessage(e);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="green-icon"
                      />
                      <div className="hover-text">
                        {texts[lang].hovers.nextSlide}
                      </div>
                    </button>
                    <button
                      className="control-button hover-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleResetMessage();
                      }}
                    >
                      <FontAwesomeIcon icon={faRedo} className="blue-icon" />
                      <div className="hover-text">
                        {texts[lang].hovers.restart}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {splitMessages[currentMessageIndex].type === "highlight" &&
              splitMessages[currentMessageIndex].highlights
                .slice(0, highlightIndex + 1)
                .map((highlight, idx) => (
                  <HighlightMessage
                    key={idx}
                    className={`highlight-${id}-${currentMessageIndex}-${idx}`}
                    imageSrc={highlight.imageSrc}
                    imageSrcWebp={highlight.imageSrcWebp}
                    imageAlt={highlight.imageAlt}
                    highlightText={highlight.highlightText}
                    position={highlight.highlightPosition}
                    textPosition={highlight.textPosition}
                  />
                ))}
            {minimizedBubbles[id] && (
              <div
                className="minimized-bubble"
                onClick={() => {
                  handleResetMessage();
                  toggleBubble(id);
                }}
                style={minimizedBubbleStyle}
              >
                ...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Character;
