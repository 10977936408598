import React, { createContext, useState, useMemo } from "react";

export const MusicContext = createContext();

export const MusicProvider = ({ children }) => {
  const [playMusic, setPlayMusic] = useState(false);

  const togglePlayMusic = () => {
    setPlayMusic((prev) => !prev);
  };

  // Memoize component to prevent unnecessary re-renders
  const value = useMemo(() => ({ playMusic, togglePlayMusic }), [playMusic]);

  return (
    <MusicContext.Provider value={value}>{children}</MusicContext.Provider>
  );
};
