import React, { useContext, useState } from "react";
import LazyImage from "@components/LazyImage";
import downloadButtonResume from "@assets/blueSkyAssets/downloadButtonResume.png";
import downloadButtonResumeWebp from "@assets/blueSkyAssets/downloadButtonResume.webp";
import resumePdf from "@assets/textsAssets/resume-PierreFilliau.pdf";

import { texts } from "@data/textsData";
import { LangContext } from "@context/LangContext";
import { logButtonClick } from "@utils/analytics";

import "@components/completeSection/blueSkyComponents/downloadButtonResumeComponent/downloadButtonResume.css";

const DownloadButtonResume = () => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const { lang } = useContext(LangContext);

  const handleDownloadClick = (e) => {
    e.preventDefault();
    logButtonClick("DownloadButtonResume", "Click", "Initiate Download");
    setShowConfirmPopup(true);
  };

  const handleConfirmDownload = () => {
    logButtonClick("DownloadButtonResume", "Click", "Confirm Download");
    setShowConfirmPopup(false);
    setTimeout(() => {
      window.open(resumePdf, "_blank");
    }, 300);
  };

  const handleCancelDownload = () => {
    setShowConfirmPopup(false);
  };

  return (
    <div className="downloadButtonResumeContainer">
      <div className="floatingWrapper">
        <div className="downloadButtonResumeWrapper">
          <a
            href={resumePdf}
            className="downloadButtonResumeLink"
            onClick={handleDownloadClick}
          >
            <LazyImage
              className="downloadButtonResume"
              srcPng={downloadButtonResume}
              srcWebp={downloadButtonResumeWebp}
              alt="Download Resume"
              priorityLevel={3}
            />
          </a>
          <div className="customTooltip">
            {texts[lang].contactForm.downloadResume.downloadTooltip}
          </div>
          {showConfirmPopup && (
            <>
              <div
                className="popupOverlay"
                onClick={handleCancelDownload}
              ></div>
              <div className="confirmPopup">
                <div className="textConfirmPopup">
                  {texts[lang].contactForm.downloadResume.confirmPart1}
                  <span className="boldOrange">
                    {" "}
                    {texts[lang].contactForm.downloadResume.confirmPart2}
                  </span>
                </div>
                <div className="popupButtons">
                  <button
                    className="confirmButton"
                    onClick={handleConfirmDownload}
                  >
                    {texts[lang].contactForm.downloadResume.confirmYes}
                  </button>
                  <button
                    className="cancelButton"
                    onClick={handleCancelDownload}
                  >
                    {texts[lang].contactForm.downloadResume.confirmNo}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadButtonResume;
