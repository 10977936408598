export function lightenColor(color, percent) {
  if (color.startsWith("rgba")) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
    let [r, g, b, a] = rgba.map(Number);
    r = Math.min(255, r + (255 - r) * percent);
    g = Math.min(255, g + (255 - g) * percent);
    b = Math.min(255, b + (255 - b) * percent);
    return `rgba(${r},${g},${b},${a})`;
  } else if (color.startsWith("#")) {
    let hex = color.substring(1, 7);
    let alpha = color.substring(7);
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }
    const num = parseInt(hex, 16);
    let r = (num >> 16) + Math.round((255 - (num >> 16)) * percent);
    let g =
      ((num >> 8) & 0x00ff) +
      Math.round((255 - ((num >> 8) & 0x00ff)) * percent);
    let b = (num & 0x0000ff) + Math.round((255 - (num & 0x0000ff)) * percent);
    return `#${((1 << 24) | (r << 16) | (g << 8) | b)
      .toString(16)
      .slice(1)
      .toUpperCase()}${alpha}`;
  }
  return color;
}
