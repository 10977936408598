// Configuration des éléments pour les animations de parallaxe
export const elementsData = [
  {
    className: "bigPlanet",
    rotationSpeed: 250, // vitesse de rotation
    rotationDirection: "clockwise", // Sens de rotation
    scrollSpeed: -5, // vitesse de défilement pour la parallaxe verticale
    parallaxStrength: 0.06, // force de la parallaxe avec le mouvement de la souris
  },
  {
    className: "atmosphBigPlanet",
    rotationSpeed: 150,
    rotationDirection: "counterclockwise",
    scrollSpeed: -5,
    parallaxStrength: 0.06,
  },

  {
    className: "planet1",
    rotationSpeed: 50,
    scrollSpeed: -0.3,
    parallaxStrength: 0.03,
  },
  {
    className: "planet2",
    rotationSpeed: 60,
    rotationDirection: "counterclockwise",

    scrollSpeed: -0.1,
    parallaxStrength: 0.005,
  },
  {
    className: "planet3",
    rotationSpeed: 50,
    rotationDirection: "counterclockwise",

    scrollSpeed: -0.5,
    parallaxStrength: 0.01,
  },
  {
    className: "planet4",
    rotationSpeed: 85,
    scrollSpeed: -0.3,
    parallaxStrength: 0.02,
  },
  {
    className: "planet7",
    rotationSpeed: 32,
    rotationDirection: "counterclockwise",
    scrollSpeed: -0.6,
    parallaxStrength: 0.04,
  },
  {
    className: "planet8",
    rotationSpeed: 55,
    rotationDirection: "counterclockwise",
    scrollSpeed: -1.5,
    parallaxStrength: 0.05,
  },
  {
    className: "planet10",
    rotationSpeed: 70,
    scrollSpeed: -0.5,
    parallaxStrength: 0.02,
  },
  {
    className: "planet11",
    rotationSpeed: 80,
    rotationDirection: "counterclockwise",
    scrollSpeed: -2,
    parallaxStrength: 0.06,
  },
  {
    className: "planet6",
    scrollSpeed: -0.1,
    parallaxStrength: 0.01,
  },

  //Station spaciale.
  {
    className: "planet5",
    rotationSpeed: 100,
    scrollSpeed: -1.0,
    parallaxStrength: 0.03,
  },
  {
    className: "planet9",
    rotationSpeed: 110,
    rotationDirection: "counterclockwise",
    scrollSpeed: -0.9,
    parallaxStrength: 0.02,
  },

  {
    className: "spaceStation1",
    scrollSpeed: -0.4,
    parallaxStrength: 0.015,
  },
  {
    className: "spaceStation2",
    scrollSpeed: -0.3,
    parallaxStrength: 0.01,
  },
  {
    className: "spaceStation3",
    scrollSpeed: -0.3,
    parallaxStrength: 0.01,
  },
  {
    className: "spaceStation4",
    scrollSpeed: -0.3,
    parallaxStrength: 0.01,
  },
  {
    className: "spaceStation5",
    scrollSpeed: -0.3,
    parallaxStrength: 0.01,
  },
  {
    className: "spaceStation6",
    scrollSpeed: -1.4,
    parallaxStrength: 0.06,
  },
  {
    className: "spaceStation8",
    scrollSpeed: -1.3,
    parallaxStrength: 0.035,
  },

  { className: "blackhole2", scrollSpeed: -0.05, parallaxStrength: 0.003 },
  {
    className: "blackhole3",
    rotationSpeed: 150,
    scrollSpeed: -0.05,
    parallaxStrength: 0.003,
  },
  { className: "rings1", scrollSpeed: -0.05, parallaxStrength: 0.002 },
  { className: "rings2", scrollSpeed: -0.05, parallaxStrength: 0.002 },
  { className: "rings3", scrollSpeed: -0.05, parallaxStrength: 0.002 },
  { className: "rings4", scrollSpeed: -0.05, parallaxStrength: 0.002 },
  {
    className: "theT",
    scrollSpeed: -0.7,
    parallaxStrength: 0.005,
  },
  {
    className: "theH",
    scrollSpeed: -1.5,
    parallaxStrength: 0.005,
  },
  {
    className: "theE",
    scrollSpeed: -0.5,
    parallaxStrength: 0.005,
  },
  {
    className: "scrumS",
    scrollSpeed: -1.2,
    parallaxStrength: 0.01,
  },
  {
    className: "scrumC",
    scrollSpeed: -0.5,
    parallaxStrength: 0.01,
  },
  {
    className: "scrumR",
    scrollSpeed: -0.6,
    parallaxStrength: 0.01,
  },
  {
    className: "scrumU",
    scrollSpeed: -0.8,
    parallaxStrength: 0.01,
  },
  {
    className: "scrumM",
    scrollSpeed: -1,
    parallaxStrength: 0.01,
  },
  {
    className: "nomadN",
    scrollSpeed: -0.7,
    parallaxStrength: 0.03,
  },
  {
    className: "nomadO",
    scrollSpeed: -0.5,
    parallaxStrength: 0.03,
  },
  {
    className: "nomadA",
    scrollSpeed: -1,
    parallaxStrength: 0.03,
  },
  {
    className: "nomadM",
    scrollSpeed: -0.6,
    parallaxStrength: 0.03,
  },
  {
    className: "nomadD",
    scrollSpeed: -1.2,
    parallaxStrength: 0.03,
  },
  { className: "fog1", scrollSpeed: -1, parallaxStrength: 0.008 },
  { className: "fog2", scrollSpeed: -1, parallaxStrength: 0.02 },
  { className: "fog3", scrollSpeed: -2, parallaxStrength: 0.04 },
  { className: "fog4", scrollSpeed: -1.5, parallaxStrength: 0.04 },
  { className: "fog5", scrollSpeed: -1.5, parallaxStrength: 0.05 },
  { className: "fog6", scrollSpeed: -1.5, parallaxStrength: 0.07 },

  // circleCloudsData
  {
    className: "circleCloud1",
    scrollSpeed: -1.7,
    rotationSpeed: 80,
    parallaxStrength: 0.04,
  },
  {
    className: "circleCloud2",
    scrollSpeed: -1.5,
    rotationSpeed: 60,
    parallaxStrength: 0.025,
  },
  {
    className: "circleCloud3",
    scrollSpeed: -0.6,
    rotationSpeed: 60,
    parallaxStrength: 0.013,
  },
  {
    className: "circleCloud4",
    scrollSpeed: -3,
    rotationSpeed: 60,
    parallaxStrength: 0.04,
  },
  {
    className: "circleCloud5",
    scrollSpeed: -2.2,
    rotationSpeed: 180,
    parallaxStrength: 0.04,
  },
  {
    className: "circleCloud6",
    scrollSpeed: -0.7,
    rotationSpeed: 100,
    parallaxStrength: 0.03,
  },
  {
    className: "circleCloud7",
    scrollSpeed: -2.4,
    rotationSpeed: 180,
    parallaxStrength: 0.05,
  },
  {
    className: "circleCloud8",
    scrollSpeed: -1.2,
    rotationSpeed: 60,
    parallaxStrength: 0.02,
  },
  {
    className: "circleCloud9",
    scrollSpeed: -1.2,
    rotationSpeed: 60,
    parallaxStrength: 0.03,
  },
  {
    className: "circleCloud10",
    scrollSpeed: -3,
    rotationSpeed: 120,
    parallaxStrength: 0.06,
  },
  {
    className: "circleCloud11",
    scrollSpeed: -0.6,
    rotationSpeed: 60,
    parallaxStrength: 0.013,
  },
  {
    className: "circleCloud12",
    scrollSpeed: -1.7,
    rotationSpeed: 100,
    parallaxStrength: 0.03,
  },
  {
    className: "circleCloud13",
    scrollSpeed: -1.2,
    rotationSpeed: 100,
    parallaxStrength: 0.02,
  },
  {
    className: "circleCloud14",
    scrollSpeed: -0.4,
    parallaxStrength: 0.015,
  },
  {
    className: "circleCloud15",
    scrollSpeed: -1.2,
    rotationSpeed: 60,
    parallaxStrength: 0.03,
  },
  {
    className: "circleCloud16",
    scrollSpeed: -0.6,
    rotationSpeed: 60,
    parallaxStrength: 0.013,
  },

  { className: "montgolfiere1", scrollSpeed: -1, parallaxStrength: 0.02 },
  { className: "montgolfiere2", scrollSpeed: -4, parallaxStrength: 0.07 },

  { className: "skyShip1", scrollSpeed: -1.5, parallaxStrength: 0.035 },
  { className: "skyShip2", scrollSpeed: -2.2, parallaxStrength: 0.04 },
  { className: "skyShip3", scrollSpeed: -1.1, parallaxStrength: 0.022 },
  { className: "skyShip4", scrollSpeed: -2.5, parallaxStrength: 0.05 },
  { className: "skyShip5", scrollSpeed: -1.55, parallaxStrength: 0.04 },
  { className: "skyShip6", scrollSpeed: -0.65, parallaxStrength: 0.017 },
  { className: "skyShip7", scrollSpeed: -0.5, parallaxStrength: 0.01 },
  { className: "skyShip8", scrollSpeed: -0.6, parallaxStrength: 0.014 },
  { className: "skyShip9", scrollSpeed: -1.4, parallaxStrength: 0.03 },
  { className: "skyShip10", scrollSpeed: -1.15, parallaxStrength: 0.023 },
  { className: "skyShip11", scrollSpeed: -0.65, parallaxStrength: 0.017 },
  { className: "skyShip12", scrollSpeed: -0.5, parallaxStrength: 0.01 },
  { className: "skyShip13", scrollSpeed: -1.8, parallaxStrength: 0.04 },
  { className: "skyShip14", scrollSpeed: -0.6, parallaxStrength: 0.015 },
  { className: "skyShip15", scrollSpeed: -1.2, parallaxStrength: 0.025 },
  { className: "skyShip16", scrollSpeed: -0.5, parallaxStrength: 0.01 },
  { className: "skyShip17", scrollSpeed: -0.7, parallaxStrength: 0.015 },
  { className: "skyShip18", scrollSpeed: -1.3, parallaxStrength: 0.032 },
  { className: "skyShip19", scrollSpeed: -1.1, parallaxStrength: 0.022 },

  { className: "flyingRock1", scrollSpeed: -0.7, parallaxStrength: 0.045 },
  { className: "flyingRock2", scrollSpeed: -0.55, parallaxStrength: 0.038 },
  { className: "flyingRock3", scrollSpeed: -0.45, parallaxStrength: 0.033 },
  { className: "flyingRock4", scrollSpeed: -0.7, parallaxStrength: 0.045 },
  { className: "flyingRock5", scrollSpeed: -0.8, parallaxStrength: 0.05 },
  { className: "flyingRock6", scrollSpeed: -0.55, parallaxStrength: 0.038 },
  { className: "flyingRock7", scrollSpeed: -0.1, parallaxStrength: 0.015 },
  { className: "flyingRock8", scrollSpeed: -0.2, parallaxStrength: 0.022 },
  { className: "flyingRock9", scrollSpeed: -0.3, parallaxStrength: 0.03 },
  { className: "flyingRock10", scrollSpeed: -0.1, parallaxStrength: 0.012 },
  { className: "flyingRock11", scrollSpeed: -0.2, parallaxStrength: 0.018 },
  { className: "flyingRock12", scrollSpeed: -0.3, parallaxStrength: 0.025 },

  { className: "groundPart1", parallaxStrength: 0.02 },
  { className: "groundPart2", parallaxStrength: 0.017 },
  { className: "groundPart3", parallaxStrength: 0.021 },
  { className: "groundPart4", parallaxStrength: 0.025 },
  { className: "groundPart5", parallaxStrength: 0.035 },
  { className: "groundPart6", parallaxStrength: 0.026 },
  { className: "groundPart7", parallaxStrength: 0.029 },
  { className: "groundPart8", parallaxStrength: 0.032 },
  { className: "groundPart9", parallaxStrength: 0.035 },
  { className: "groundPart10", parallaxStrength: 0.032 },
  { className: "groundPart11", parallaxStrength: 0.008 },
  { className: "groundPart12", parallaxStrength: 0.04 },

  { className: "city1", parallaxStrength: 0.05 },
  { className: "city2", parallaxStrength: 0.045 },
  { className: "city3", parallaxStrength: 0.01 },
  { className: "city4", parallaxStrength: 0.02 },
  { className: "city5", parallaxStrength: 0.03 },

  { className: "character1", parallaxStrength: 0.022 },
  { className: "character2", parallaxStrength: 0.027 },
  { className: "character3", parallaxStrength: 0.021 },
  { className: "character4", parallaxStrength: 0.038 },
  { className: "character5", parallaxStrength: 0.02 },
  { className: "character6", parallaxStrength: 0.028 },
  { className: "character7", parallaxStrength: 0.025 },
  { className: "character8", parallaxStrength: 0.022 },
  { className: "character9", parallaxStrength: 0.023 },
  { className: "character10", parallaxStrength: 0.024 },
  { className: "character11", parallaxStrength: 0.028 },
  { className: "character12", parallaxStrength: 0.025 },
  { className: "character13", parallaxStrength: 0.021 },
  { className: "character14", parallaxStrength: 0.026 },
  { className: "character15", parallaxStrength: 0.024 },
  { className: "character16", parallaxStrength: 0.022 },
  { className: "character17", parallaxStrength: 0.028 },
  { className: "character18", parallaxStrength: 0.021 },
  { className: "character19", parallaxStrength: 0.026 },
  { className: "character20", parallaxStrength: 0.023 },
  { className: "character21", parallaxStrength: 0.025 },
  { className: "character22", parallaxStrength: 0.021 },
  {
    className: "downloadButtonResume",
    parallaxStrength: 0.025,
  },
];

export const horizontalSpaceShipAnimation = [
  { className: "spaceShipA1", duration: 15, angle: -25 },
  { className: "spaceShipA2", duration: 45, angle: -25 },
  { className: "spaceShipA3", duration: 20, angle: -25 },
  { className: "spaceShipA4", duration: 30, angle: -25 },
  { className: "spaceShipA5", duration: 43, angle: -25 },
  { className: "spaceShipA6", duration: 42, angle: -25 },
  { className: "spaceShipB1", duration: 25, angle: -180 },
  { className: "spaceShipB2", duration: 10, angle: -180 },
  { className: "spaceShipB3", duration: 30, angle: -180 },
  { className: "spaceShipB4", duration: 20, angle: -180 },
  { className: "spaceShipC1", duration: 10, angle: 0 },
  { className: "spaceShipC2", duration: 8, angle: 0 },
  { className: "spaceShipC3", duration: 8, angle: 0 },
  { className: "spaceShipD1", duration: 14, angle: -180 },
  { className: "spaceShipD2", duration: 10, angle: -180 },
  { className: "spaceShipD3", duration: 12, angle: -180 },
  { className: "spaceShipD4", duration: 16, angle: -180 },
  { className: "spaceShipE1", duration: 18, angle: 3 },
  { className: "spaceShipE2", duration: 15, angle: 3 },
  { className: "spaceShipE3", duration: 12, angle: 3 },
  { className: "spaceShipF1", duration: 25, angle: -7 },
  { className: "spaceShipF2", duration: 20, angle: -7 },
  { className: "spaceShipF3", duration: 15, angle: -7 },
  { className: "spaceShipF4", duration: 10, angle: -7 },

  { className: "spaceShipH1", duration: 35, angle: 0 },
  { className: "spaceShipH2", duration: 20, angle: 0 },
  { className: "spaceShipH3", duration: 15, angle: 0 },
];

export const longCloudsConfig = [
  { className: "longCloud1", duration: 100 },
  { className: "longCloud2", duration: 50 },
  { className: "longCloud3", duration: 150 },
  { className: "longCloud4", duration: 200 },
  { className: "longCloud5", duration: 250 },
  { className: "longCloud6", scrollSpeed: -1, duration: 150 },
  { className: "longCloud7", scrollSpeed: -1, duration: 150 },
  { className: "longCloud8", scrollSpeed: -1, duration: 150 },
  { className: "longCloud9", scrollSpeed: -1, duration: 150 },
  { className: "longCloud10", scrollSpeed: -1, duration: 150 },
  { className: "longCloud11", scrollSpeed: -1.5, duration: 100 },
  { className: "longCloud12", scrollSpeed: -1.5, duration: 100 },
  { className: "longCloud13", scrollSpeed: -1.5, duration: 100 },
  { className: "longCloud14", scrollSpeed: -1.5, duration: 100 },
  { className: "longCloud15", scrollSpeed: -2.5, duration: 50 },
  { className: "longCloud16", scrollSpeed: -2.5, duration: 50 },
  { className: "longCloud17", scrollSpeed: -2.5, duration: 50 },
  { className: "longCloud18", scrollSpeed: -2.5, duration: 50 },
  { className: "longCloud19", scrollSpeed: -0.5, duration: 200 },
  { className: "longCloud20", scrollSpeed: -0.5, duration: 200 },
  { className: "longCloud21", scrollSpeed: -0.5, duration: 200 },
  { className: "longCloud22", scrollSpeed: -0.5, duration: 200 },
  { className: "longCloud23", scrollSpeed: -0.5, duration: 200 },
];
