export const minimapPointsData = [
  { id: 1, name: "welcome", color: "#0060FF70", position: 9 },
  { id: 2, name: "navigation", color: "#0060FF70", position: 11 },
  { id: 3, name: "presentation", color: "#0060FF70", position: 15 },

  { id: 4, name: "skills", color: "rgba(29, 192, 228, 0.6)", position: 23 },
  { id: 5, name: "agility", color: "rgba(29, 192, 228, 0.6)", position: 26 },
  { id: 6, name: "philosophy", color: "rgba(29, 192, 228, 0.6)", position: 35 },

  { id: 7, name: "developper", color: "#FF9C00A8", position: 43 },
  { id: 8, name: "technology", color: "#FF9C00A8", position: 48 },

  { id: 9, name: "postures", color: "#36BF0096", position: 56 },
  { id: 10, name: "roles", color: "#36BF0096", position: 59 },

  { id: 11, name: "proproject", color: "#ff3917a8", position: 63 },
  {
    id: 12,
    name: "persoproject",
    color: "#ff3917a8",
    position: 66,
  },

  { id: 13, name: "miscellaneous", color: "#ff3917a8", position: 69 },
  { id: 14, name: "certifications", color: "#FFD700A8", position: 79 },
  { id: 15, name: "diplomas", color: "#FFD700A8", position: 82 },

  { id: 16, name: "contact", color: "#bfb6a88a", position: 87 },
  { id: 17, name: "resume", color: "#bfb6a88a", position: 89 },
];
