import React, { useContext, useRef, useCallback } from "react";
import { LangContext } from "@context/LangContext";
import { texts } from "@data/textsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import backgroundImage from "@assets/charactersAssets/sectionBackground.png";
import "./SectionView.css";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { lightenColor } from "@utils/colorUtils";
import LazyImage from "@components/LazyImage";
// import customScrollbar from "@assets/charactersAssets/sectionScroller.png";
// import customScrollbarWebp from "@assets/charactersAssets/sectionScroller.webp";

const SectionView = ({
  sectionText,
  onClose,
  backgroundColor,
  sectionName,
}) => {
  const { lang } = useContext(LangContext);
  const closeSectionButtonRef = useRef(null);
  const sectionInnerContentRef = useRef(null);
  const customScrollbarRef = useRef(null);
  const scrollbarTrailRef = useRef(null);
  const sectionContentRef = useRef(null);
  const backgroundFilterRef = useRef(null);

  const updateScrollbarVisibility = useCallback(() => {
    const sectionInnerContent = sectionInnerContentRef.current;
    const customScrollbar = customScrollbarRef.current;
    const scrollbarTrail = scrollbarTrailRef.current;
    const scrollableHeight =
      sectionInnerContent.scrollHeight - sectionInnerContent.clientHeight;

    if (scrollableHeight <= 0) {
      customScrollbar.style.display = "none";
      scrollbarTrail.style.display = "none";
    } else {
      customScrollbar.style.display = "block";
      scrollbarTrail.style.display = "block";
    }
  }, []);

  // Animation de fermeture
  const handleCloseSection = useCallback(() => {
    gsap
      .timeline({
        onComplete: onClose,
      })
      .to(sectionContentRef.current, {
        height: 0,
        duration: 0.8,
        ease: "power2.inOut",
        transformOrigin: "top",
      });
  }, [onClose]);

  const handleResize = useCallback(() => {
    gsap.set(closeSectionButtonRef.current, { scale: 1 });
    updateScrollbarVisibility();
  }, [updateScrollbarVisibility]);

  const handleScroll = useCallback(() => {
    const sectionInnerContent = sectionInnerContentRef.current;
    const customScrollbar = customScrollbarRef.current;
    const scrollableHeight =
      sectionInnerContent.scrollHeight - sectionInnerContent.clientHeight;
    const scrollTop = sectionInnerContent.scrollTop;
    const scrollbarHeight =
      sectionInnerContent.clientHeight - customScrollbar.clientHeight;
    const scrollRatio = scrollTop / scrollableHeight;
    const translateY = scrollRatio * scrollbarHeight;

    gsap.to(customScrollbar, {
      y: translateY,
      ease: "power2.out",
      duration: 0.3,
    });

    gsap.to(scrollbarTrailRef.current, {
      y: translateY,
      ease: "power2.out",
      duration: 1,
    });
  }, []);

  const handleMouseDown = useCallback((event) => {
    const customScrollbar = customScrollbarRef.current;
    const sectionInnerContent = sectionInnerContentRef.current;
    const startY = event.clientY;
    const startScrollTop = sectionInnerContent.scrollTop;
    const handleMouseMove = (event) => {
      const deltaY = event.clientY - startY;
      const scrollableHeight =
        sectionInnerContent.scrollHeight - sectionInnerContent.clientHeight;
      const scrollbarHeight =
        sectionInnerContent.clientHeight - customScrollbar.clientHeight;
      const scrollRatio = scrollableHeight / scrollbarHeight;
      sectionInnerContent.scrollTop = startScrollTop + deltaY * scrollRatio;
    };
    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        sectionContentRef.current &&
        !sectionContentRef.current.contains(event.target)
      ) {
        handleCloseSection();
      }
    },
    [handleCloseSection]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        handleCloseSection();
      }
    },
    [handleCloseSection]
  );

  useGSAP(() => {
    window.addEventListener("resize", handleResize);
    sectionInnerContentRef.current.addEventListener("scroll", handleScroll);
    customScrollbarRef.current.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    updateScrollbarVisibility();

    // Appliquer la couleur de fond et le box-shadow
    if (backgroundColor) {
      const lightBackgroundColor = lightenColor(backgroundColor, 0.5);
      const lighterBackgroundColor = lightenColor(backgroundColor, 0.7);
      backgroundFilterRef.current.style.background = backgroundColor;
      backgroundFilterRef.current.style.boxShadow = `0 0 10px ${backgroundColor}, 0 0 15px ${backgroundColor}`;
      scrollbarTrailRef.current.style.background = `linear-gradient(180deg, ${lightBackgroundColor}, rgba(0, 0, 0, 0))`;
      scrollbarTrailRef.current.style.boxShadow = `0 0 15px ${lighterBackgroundColor}, 0 0 30px ${lighterBackgroundColor}`;
      closeSectionButtonRef.current.style.setProperty(
        "--hover-shadow",
        `0 0 10px ${backgroundColor}, 0 0 20px ${backgroundColor}`
      );
    }

    // Animation d'ouverture
    gsap.fromTo(
      sectionContentRef.current,
      {
        height: 0,
        opacity: 0,
        transformOrigin: "top",
      },
      {
        height: "80vh",
        opacity: 1,
        duration: 0.8,
        ease: "power1.inOut",
      }
    );

    return () => {
      window.removeEventListener("resize", handleResize);
      sectionInnerContentRef.current.removeEventListener(
        "scroll",
        handleScroll
      );
      customScrollbarRef.current.removeEventListener(
        "mousedown",
        handleMouseDown
      );
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    handleResize,
    handleScroll,
    handleMouseDown,
    updateScrollbarVisibility,
    handleClickOutside,
    handleKeyDown,
    backgroundColor,
  ]);

  const handleButtonClick = (buttonRef) => {
    gsap.fromTo(
      buttonRef.current,
      { scale: 1 },
      { scale: 0.9, duration: 0.1, yoyo: true, repeat: 1 }
    );
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const highlightText = (text, highlights) => {
    if (!highlights) return text;

    const escapedHighlights = highlights.map((h) => escapeRegExp(h.text));
    const parts = text.split(
      new RegExp(`(${escapedHighlights.join("|")})`, "gi")
    );

    return parts.map((part, index) => {
      const highlight = highlights.find(
        (h) => h.text.toLowerCase() === part.toLowerCase()
      );
      if (highlight) {
        return (
          <span key={index} className={highlight.style}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  // Fonction pour combiner les paragraphGroup en un seul paragraphe avec highlights
  const combineParagraphGroups = (sectionText) => {
    return sectionText.map((text, index) => {
      if (text.type === "paragraphGroup") {
        const combinedContent = text.content.map((paragraph, idx) => (
          <React.Fragment key={`${index}-${idx}`}>
            {highlightText(paragraph.text, paragraph.highlighted)}
            {idx < text.content.length - 1 && <br />}{" "}
          </React.Fragment>
        ));
        return {
          ...text,
          content: (
            <p key={text.type} className="section-paragraph">
              {combinedContent}
            </p>
          ),
        };
      }
      return text;
    });
  };

  const combinedSectionText = combineParagraphGroups(sectionText);

  return (
    <div className={`section-view ${sectionName}`}>
      <div className="section-content" ref={sectionContentRef}>
        <div className="background-container">
          <div className="background-filter" ref={backgroundFilterRef}></div>
          <img
            src={backgroundImage}
            alt="Background"
            className="background-image"
          />
        </div>
        <div className="content-overlay">
          <div className="button-section">
            <div className="close-section-button-container">
              <button
                className="control-button close-control-button hover-container"
                onClick={() => {
                  handleCloseSection();
                  handleButtonClick(closeSectionButtonRef);
                }}
                ref={closeSectionButtonRef}
              >
                <FontAwesomeIcon icon={faTimes} />
                <div className="hover-text">
                  {texts[lang]?.hovers?.closeSection}
                </div>
              </button>
            </div>
          </div>
          <div className="text-container">
            <div className="section-inner-content" ref={sectionInnerContentRef}>
              {combinedSectionText.map((text, index) => {
                switch (text.type) {
                  case "title":
                    return (
                      <div key={index} className="section-title">
                        {highlightText(text.content, text.highlighted)}
                      </div>
                    );
                  case "subtitle":
                    return (
                      <div key={index} className="section-subtitle">
                        {highlightText(text.content, text.highlighted)}
                      </div>
                    );
                  case "paragraph":
                    return (
                      <p key={index} className="section-paragraph">
                        {highlightText(text.content, text.highlighted)}
                      </p>
                    );
                  case "subsubtitle":
                    return (
                      <div key={index} className="section-subsubtitle">
                        {highlightText(text.content, text.highlighted)}
                      </div>
                    );
                  case "paragraphGroup":
                    return <div key={index}>{text.content}</div>;
                  case "list":
                    return (
                      <ul key={index} className="section-list">
                        {text.content.map((item, idx) => (
                          <li key={idx}>
                            {highlightText(item, text.highlighted)}
                          </li>
                        ))}
                      </ul>
                    );
                  case "highlight":
                    return (
                      <div key={index} className="section-highlight">
                        {text.highlights.map((highlight, highlightIndex) => (
                          <div key={highlightIndex} className="highlight-item">
                            <LazyImage
                              className="highlight-image"
                              srcPng={highlight.imageSrc}
                              srcWebp={highlight.imageSrcWebp}
                              alt={highlight.imageAlt}
                              priorityLevel={3}
                            />
                            <div className="highlight-text-container">
                              <div className="highlight-text">
                                {highlightText(
                                  highlight.highlightText,
                                  highlight.highlighted
                                )}
                              </div>
                              <p className="highlight-content">
                                {highlightText(
                                  highlight.content,
                                  highlight.highlighted
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  case "socialLink":
                    return (
                      <div key={index} className="section-social-link">
                        <a
                          href={text.content.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link-anchor"
                        >
                          <LazyImage
                            className="social-link-logo"
                            srcPng={text.content.srcPng}
                            srcWebp={text.content.srcWebp}
                            alt={text.content.alt}
                            priorityLevel={3}
                          />
                          <span className="social-link-text">
                            {text.content.text}
                          </span>
                        </a>
                      </div>
                    );
                  default:
                    return (
                      <p key={index} className="section-paragraph">
                        {highlightText(text.content, text.highlighted)}
                      </p>
                    );
                }
              })}
            </div>
            <div className="scrollbar-section">
              <div className="custom-scrollbar-container">
                <div className="scrollbar-trail" ref={scrollbarTrailRef}></div>
                <div className="custom-scrollbar" ref={customScrollbarRef}>
                  {/* <LazyImage
                    srcPng={customScrollbar}
                    srcWebp={customScrollbarWebp}
                    alt="ustom Scrollbar"
                    priorityLevel={3}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionView;
