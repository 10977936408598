import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { usePriority } from "@context/PriorityContext";
import "./LazyImage.css";

const LazyImage = forwardRef(
  (
    { srcPng, srcWebp, alt, priorityLevel, className, onClick, onLoad },
    ref
  ) => {
    const [isLoaded, setIsLoaded] = useState(priorityLevel === 1);
    const imgRef = useRef();
    const {
      priority1Loaded,
      setPriority1Loaded,
      priority2Loaded,
      setPriority2Loaded,
    } = usePriority();

    useImperativeHandle(ref, () => imgRef.current);

    useEffect(() => {
      const img = imgRef.current;

      const loadImage = (imageSrc) => {
        if (!imageSrc) {
          console.error("Image source is undefined");
          return;
        }
        img.src = imageSrc;
        img.onload = () => {
          setIsLoaded(true);
          if (priorityLevel === 1) {
            setPriority1Loaded(true);
          } else if (priorityLevel === 2) {
            setPriority2Loaded(true);
          }
          if (onLoad) onLoad(); // Callback when image is loaded
        };
        img.onerror = () => {
          if (imageSrc !== srcPng) {
            loadImage(srcPng); // Fallback to original image
          }
        };
      };

      const imageToLoad = srcWebp || srcPng; // Use srcPng if srcWebp is not defined

      if (priorityLevel === 1) {
        if (imageToLoad) {
          loadImage(imageToLoad);
        } else {
          console.error("Priority 1 image source is undefined");
        }
      } else if (priorityLevel === 2) {
        if (priority1Loaded) {
          loadImage(imageToLoad);
        } else {
          const intervalId = setInterval(() => {
            if (priority1Loaded) {
              loadImage(imageToLoad);
              clearInterval(intervalId);
            }
          }, 100);
        }
      } else if (priorityLevel === 3) {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && priority2Loaded) {
                if (imageToLoad) {
                  loadImage(imageToLoad);
                } else {
                  console.error("Priority 3 image source is undefined");
                }
                observer.disconnect();
              }
            });
          },
          { rootMargin: "200px 0px 50px 0px" }
        );

        observer.observe(img);

        return () => {
          if (observer) {
            observer.disconnect();
          }
        };
      }
    }, [
      srcPng,
      srcWebp,
      alt,
      priorityLevel,
      priority1Loaded,
      priority2Loaded,
      setPriority1Loaded,
      setPriority2Loaded,
      onLoad,
    ]);

    return (
      <div className="lazy-image-container">
        {!isLoaded && <div className="lazy-image-placeholder"></div>}
        <picture>
          {srcWebp && <source srcSet={srcWebp} type="image/webp" />}
          <img
            ref={imgRef}
            src={priorityLevel === 1 ? srcWebp || srcPng : ""}
            alt={alt}
            className={`${className} ${isLoaded ? "loaded" : "loading"}`}
            onClick={onClick}
          />
        </picture>
      </div>
    );
  }
);

export default LazyImage;
