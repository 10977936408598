import React, { useMemo } from "react";
import {
  asteroides,
  asteroidesClones,
  imagesStarrySky,
  imagesStarrySkyWebp,
} from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";

const Asteroides = () => {
  const asteroidesElements = useMemo(
    () => (
      <>
        {asteroides.map((className, index) => (
          <div
            key={`${className}-${index}`}
            className={`${className}Container`}
          >
            <div className={`${className}Wrapper`}>
              <LazyImage
                className={className}
                srcPng={imagesStarrySky[className]}
                srcWebp={imagesStarrySkyWebp[`${className}Webp`]}
                alt=""
                priorityLevel={2}
              />
            </div>
          </div>
        ))}
        {asteroidesClones.map((className, index) => (
          <div
            key={`${className}-${index}-clone`}
            className={`${className}Container`}
          >
            <div className={`${className}Wrapper`}>
              <LazyImage
                className={className}
                srcPng={imagesStarrySky[className.replace("-clone", "")]}
                srcWebp={
                  imagesStarrySkyWebp[className.replace("-clone", "Webp")]
                }
                alt=""
                priorityLevel={2}
              />
            </div>
          </div>
        ))}
      </>
    ),
    []
  );
  return <div className="asteroidesWrapper">{asteroidesElements}</div>;
};

export default Asteroides;
