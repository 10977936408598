import React, { createContext, useState, useContext } from "react";

const PriorityContext = createContext();

export const usePriority = () => useContext(PriorityContext);

export const PriorityProvider = ({ children }) => {
  const [priority1Loaded, setPriority1Loaded] = useState(false);
  const [priority2Loaded, setPriority2Loaded] = useState(false);

  const value = {
    priority1Loaded,
    setPriority1Loaded,
    priority2Loaded,
    setPriority2Loaded,
  };

  return (
    <PriorityContext.Provider value={value}>
      {children}
    </PriorityContext.Provider>
  );
};
