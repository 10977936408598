import React, { useMemo } from "react";
import {
  nomadLetters,
  imagesStarrySky,
  imagesStarrySkyWebp,
} from "@data/StarrySkyData";
import LazyImage from "@components/LazyImage";

const LettersNomad = () => {
  const lettersElements = useMemo(
    () =>
      nomadLetters.map((className, index) => (
        <div key={`${className}-${index}`} className={`${className}Container`}>
          <div className="floatingWrapper">
            <div className={`${className}Wrapper`}>
              <LazyImage
                className={className}
                srcPng={imagesStarrySky[className]}
                srcWebp={imagesStarrySkyWebp[`${className}Webp`]}
                alt=""
                priorityLevel={2}
              />
            </div>
          </div>
        </div>
      )),
    []
  );

  return <>{lettersElements}</>;
};

export default LettersNomad;
