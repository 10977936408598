import React, { useState, useEffect, useContext } from "react";
import "@components/completeSection/completeSection.css";
import "@components/completeSection/blueSkyComponents/blueSky.css";
import "@components/completeSection/starrySkyComponents/lettersComponent/letterPositions.css";
import "@components/completeSection/starrySkyComponents/planetsComponent/planetsPositions.css";
import "@components/completeSection/starrySkyComponents/astresPositions.css";
import "@components/completeSection/starrySkyComponents/spaceStationsComponent/spaceStationPositions.css";
import "@components/completeSection/starrySkyComponents/asteroidesComponent/asteroides.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LangContext } from "@context/LangContext";
import Minimap from "./minimapComponent/Minimap";

import { imagesStarrySky } from "@data/StarrySkyData";

import {
  elementsData,
  horizontalSpaceShipAnimation,
  longCloudsConfig,
} from "@data/CompleteSectionAnimationData";

import LettersThe from "./starrySkyComponents/lettersComponent/LettersThe.js";
import LettersScrum from "./starrySkyComponents/lettersComponent/LettersScrum";
import LettersNomad from "./starrySkyComponents/lettersComponent/LettersNomad";
import Asteroides from "./starrySkyComponents/asteroidesComponent/Asteroides";
import Planets from "./starrySkyComponents/planetsComponent/Planets";
import Blackholes from "./starrySkyComponents/blackholesComponent/Blackholes";
import Rings from "./starrySkyComponents/ringsComponent/Rings";
import BigPlanet from "./starrySkyComponents/bigPlanetComponent/BigPlanet";
import Fog from "./starrySkyComponents/fogComponent/Fog";
import SpaceShips from "./starrySkyComponents/spaceShipsComponent/SpaceShips";
import SpaceStations from "./starrySkyComponents/spaceStationsComponent/SpaceStations";
import CircleClouds from "./blueSkyComponents/CircleClouds";
import Cities from "./blueSkyComponents/Cities";
import FlyingRocks from "./blueSkyComponents/FlyingRocks";
import SkyShips from "./blueSkyComponents/SkyShips";
import Montgolfieres from "./blueSkyComponents/Montgolfieres";
import LongClouds from "./blueSkyComponents/LongClouds";
import GroundParts from "./blueSkyComponents/GroundParts";
import Characters from "./characterComponent/Characters";
import LoadingPage from "./loadingPageComponents/LoadingPage";

import useGSAPAnimations from "./useGSAPAnimations";
import LazyImage from "@components/LazyImage";
import ContactForm from "./contactFormComponent/ContactForm.js";
import DownloadButtonResume from "./blueSkyComponents/downloadButtonResumeComponent/DownloadButtonResume.js";

gsap.registerPlugin(ScrollTrigger);

const CompleteSection = () => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    if (showLoadingScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showLoadingScreen]);

  useGSAPAnimations(
    elementsData,
    horizontalSpaceShipAnimation,
    longCloudsConfig
  );

  return (
    <div className="completeSection">
      {showLoadingScreen && (
        <LoadingPage setShowLoadingScreen={setShowLoadingScreen} />
      )}
      <>
        {/* Background too big for webp, could be optimisable */}
        <LazyImage
          srcPng={imagesStarrySky.completeSkyBg}
          alt="Complete background Background"
          className="starrySkyBg"
          priorityLevel={2}
        />
        <div className="starrySkySection">
          <LettersThe />
          <LettersScrum />
          <LettersNomad />
          <Asteroides />
          <Planets />
          <Blackholes />
          <Rings />
          <BigPlanet />
          <Fog />
          <SpaceShips />
          <SpaceStations />
        </div>
        <div className="blueSkySection">
          <CircleClouds />
          <Cities />
          <FlyingRocks />
          <SkyShips />
          <Montgolfieres />
          <LongClouds />
          <GroundParts />
          <DownloadButtonResume />
        </div>
        <div className="characterSection">
          <Characters lang={lang} />
        </div>
        <Minimap />
        <ContactForm />
      </>
    </div>
  );
};

export default React.memo(CompleteSection);
