import React from "react";
import {
  longClouds,
  imagesBlueSky,
  imagesBlueSkyWebp,
} from "@data/blueSkyData";
import LazyImage from "@components/LazyImage";

const LongClouds = () => (
  <>
    {longClouds.map((className, index) => (
      <React.Fragment key={index}>
        <div className={`${className}Container`}>
          <div className={`${className}Wrapper`}>
            <LazyImage
              className={className}
              srcPng={imagesBlueSky[className]}
              srcWebp={imagesBlueSkyWebp[`${className}Webp`]}
              alt={`${className} original`}
              priorityLevel={3}
            />
          </div>
        </div>
        <div className={`${className}Container clone`}>
          <div className={`${className}Wrapper`}>
            {/* WIP LazyImages Je ne sais pas pourquoi les clones apparaissent pas, 
            ils apparaissent dans le DOM apres quelque manip dans la console mais idk  */}
            <picture>
              <source
                srcSet={imagesBlueSkyWebp[`${className}Webp`]}
                type="image/webp"
              />
              <img
                src={imagesBlueSky[className]}
                alt={`${className} clone`}
                className={className}
              />
            </picture>
          </div>
        </div>
      </React.Fragment>
    ))}
  </>
);

export default LongClouds;
