import { useRef, useCallback } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { asteroides, asteroidesClones } from "@data/StarrySkyData";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const useGSAPAnimations = (
  elementsData,
  horizontalSpaceShipAnimation,
  longCloudsConfig
) => {
  const parallaxYPositions = useRef({});
  const mouseMoveAnimations = useRef({});
  const animationFrameRef = useRef();

  const isElementVisible = (className) => {
    const element = document.querySelector(`.${className}Container`);
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom >= 0;
  };

  const activateMouseMoveAnimation = (className) => {
    if (mouseMoveAnimations.current.hasOwnProperty(className)) {
      parallaxYPositions.current[className] = true;
    }
  };

  const deactivateMouseMoveAnimation = (className) => {
    parallaxYPositions.current[className] = false;
  };

  const handleMouseMove = useCallback(
    (event) => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const posX = (event.clientX / viewportWidth - 0.5) * viewportWidth * 2;
      const posY = (event.clientY / viewportHeight - 0.5) * viewportHeight * 2;

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      animationFrameRef.current = requestAnimationFrame(() => {
        elementsData.forEach((element) => {
          if (isElementVisible(element.className)) {
            activateMouseMoveAnimation(element.className);
          }
          if (parallaxYPositions.current[element.className]) {
            const { x, y } = mouseMoveAnimations.current[element.className];
            x(posX * element.parallaxStrength);
            y(posY * element.parallaxStrength);
          }
        });
      });
    },
    [elementsData]
  );

  const addWillChange = (element, property) => {
    element.style.willChange = property;
  };

  const removeWillChange = (element) => {
    element.style.willChange = "";
  };

  useGSAP(() => {
    const animateAsteroids = (asteroidsArray) => {
      asteroidsArray.forEach((className, index) => {
        const element = document.querySelector(`.${className}`);
        if (element) addWillChange(element, "transform");
        gsap.to(`.${className}`, {
          x: "-100vw",
          duration: 50 - index * 15,
          repeat: -1,
          ease: "none",
          onRepeat: () => gsap.set(`.${className}`, { x: "0vw" }),
          onComplete: () => {
            if (element) removeWillChange(element);
          },
        });
      });
    };

    elementsData.forEach((element) => {
      const elementContainer = document.querySelector(
        `.${element.className}Container`
      );
      const elementWrapper = document.querySelector(
        `.${element.className}Wrapper`
      );

      if (elementWrapper) addWillChange(elementWrapper, "transform");

      const parallaxTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: elementContainer,
          start: "clamp(top bottom)",
          end: "bottom top",
          scrub: true,
          onEnter: () => {
            activateMouseMoveAnimation(element.className);
          },
          onLeave: () => {
            deactivateMouseMoveAnimation(element.className);
          },
          onEnterBack: () => activateMouseMoveAnimation(element.className),
          onLeaveBack: () => deactivateMouseMoveAnimation(element.className),
          onRefresh: (self) => {
            if (self.isActive) {
              activateMouseMoveAnimation(element.className);
            }
          },
          onComplete: () => {
            if (elementWrapper) removeWillChange(elementWrapper);
          },
        },
      });

      if (element.scrollSpeed) {
        parallaxTimeline.to(
          elementWrapper,
          {
            y: `+=${window.innerHeight * element.scrollSpeed}`,
            ease: "none",
          },
          0
        );
      }

      if (element.rotationSpeed) {
        const direction =
          element.rotationDirection === "counterclockwise" ? -1 : 1;
        gsap.to(`.${element.className}`, {
          rotation: 360 * direction,
          duration: element.rotationSpeed,
          repeat: -1,
          ease: "linear",
          onStart: () => {
            const el = document.querySelector(`.${element.className}`);
            if (el) addWillChange(el, "transform");
          },
          onComplete: () => {
            const el = document.querySelector(`.${element.className}`);
            if (el) removeWillChange(el);
          },
        });
      }

      if (element.parallaxStrength) {
        mouseMoveAnimations.current[element.className] = {
          x: gsap.quickTo(`.${element.className}`, "x", {
            duration: 0.5,
            ease: "easeInOutQuint",
          }),
          y: gsap.quickTo(`.${element.className}`, "y", {
            duration: 0.5,
            ease: "easeInOutQuint",
          }),
        };
      }

      if (isElementVisible(element.className)) {
        activateMouseMoveAnimation(element.className);
      }
    });

    animateAsteroids(asteroides);
    animateAsteroids(asteroidesClones);

    const animations = {};

    horizontalSpaceShipAnimation.forEach(({ className, duration, angle }) => {
      const element = document.querySelector(`.${className}`);
      if (element) addWillChange(element, "transform");

      const cosAngle = Math.cos(angle * (Math.PI / 180));
      const sinAngle = Math.sin(angle * (Math.PI / 180));
      const moveDistance =
        Math.max(window.innerWidth, window.innerHeight) * 1.5;

      const animation = gsap
        .timeline({ repeat: -1, paused: true })
        .to(element, {
          x: `+=${moveDistance * cosAngle}`,
          y: `+=${moveDistance * sinAngle}`,
          duration: duration / 2,
          ease: "none",
          onComplete: () => {
            if (element) removeWillChange(element);
          },
        })
        .set(element, {
          x: `-=${moveDistance * cosAngle * 2}`,
          y: `-=${moveDistance * sinAngle * 2}`,
        })
        .to(element, {
          x: `+=${moveDistance * cosAngle}`,
          y: `+=${moveDistance * sinAngle}`,
          duration: duration / 2,
          ease: "none",
        });

      animations[className] = animation;

      ScrollTrigger.create({
        trigger: element,
        start: "top bottom+=400",
        end: "bottom top-=400",
        onEnter: () => animations[className].play(),
        onLeave: () => animations[className].pause(),
        onEnterBack: () => animations[className].play(),
        onLeaveBack: () => animations[className].pause(),
      });
    });

    longCloudsConfig.forEach((config) => {
      const containers = document.querySelectorAll(
        `.${config.className}Container, .${config.className}Container.clone`
      );
      containers.forEach((container) => {
        const wrapper = container.querySelector(`.${config.className}Wrapper`);
        const isClone = container.classList.contains("clone");
        const startPosition = isClone ? "100vw" : "0vw";
        const endPosition = isClone ? "200vw" : "100vw";

        const horizontalAnimation = gsap.fromTo(
          wrapper,
          { x: startPosition },
          {
            x: endPosition,
            duration: config.duration,
            ease: "none",
            repeat: -1,
            paused: true,
            modifiers: { x: (x) => `${parseFloat(x) % 200}vw` },
            onRepeat: () => {
              gsap.set(wrapper, { x: startPosition });
            },
            onStart: () => addWillChange(wrapper, "transform"),
            onComplete: () => removeWillChange(wrapper),
          }
        );

        ScrollTrigger.create({
          trigger: container,
          start: "top bottom+=400",
          end: "bottom top-=400",
          onEnter: () => horizontalAnimation.play(),
          onLeave: () => horizontalAnimation.pause(),
          onEnterBack: () => horizontalAnimation.play(),
          onLeaveBack: () => horizontalAnimation.pause(),
        });

        if (config.scrollSpeed) {
          const parallaxTimeline = gsap.timeline({
            scrollTrigger: {
              trigger: container,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          });
          parallaxTimeline.to(
            wrapper,
            {
              y: `+=${window.innerHeight * config.scrollSpeed}`,
              ease: "none",
            },
            0
          );
        }
      });
    });

    // Ajout de l'animation de flottement
    const floatingElements = document.querySelectorAll(".floatingWrapper");

    floatingElements.forEach((element) => {
      const randomDuration = Math.random() * 1 + 1.5;
      const randomAmplitude = Math.random() * 10 + 5;
      const randomDelay = Math.random() * 0.5;

      const floatingAnimation = gsap.to(element, {
        y: `-=${randomAmplitude}`,
        duration: randomDuration,
        yoyo: true,
        repeat: -1,
        ease: "power1.inOut",
        paused: true,
        delay: randomDelay,
        overwrite: "auto", // Ajout pour éviter les conflits
      });

      ScrollTrigger.create({
        trigger: element,
        start: "top bottom",
        end: "bottom top",
        onEnter: () => {
          floatingAnimation.play();
        },
        onLeave: () => {
          floatingAnimation.pause();
        },
        onEnterBack: () => {
          floatingAnimation.play();
        },
        onLeaveBack: () => {
          floatingAnimation.pause();
        },
      });
    });

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);
};

export default useGSAPAnimations;
